import { useContext } from 'react'
import { Image } from 'react-bootstrap'
import { ThemeContext } from '../utils/use-theme'
import './no-installations-component.css'
import DotLine from './svg/no-installations-dot-line'

export function NoInstallationsComponent() {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      <div id={theme} className="no-installations">
        <div className="modal-frame-no-installations">
          <div className="heading-frame-no-installations" style={{ alignItems: 'flex-start' }}>
            <p className="heading-text-no-installations h-6">No installations found</p>
          </div>
          <p className="paragraph-no-installations paragraph">
            It looks like you don’t have any complete installations. It could be that pixeebot was uninstalled by an
            administrator, or a request is pending approval. We suggest you check GitHub and then check back here. Or,
            go ahead and{' '}
            <a className="docs-link" href={`https://github.com/apps/pixeebot/installations/new`} target="_blank">
              start a new installation.
            </a>
          </p>
        </div>
      </div>
      <div className="pixee-white">
        <Image width={88} src={theme === 'dark' ? 'pixee-white.png' : 'pixee-black.png'} />
      </div>
      <div className="dot-line">
        <DotLine theme={theme} />
      </div>
      <div id={theme} className="background-stardust">
        {theme === 'dark' && (
          <>
            <Image src="stardust.png" width="50%" height="100%" />
            <Image src="stardust.png" width="50%" height="100%" />
          </>
        )}
      </div>
    </>
  )
}
