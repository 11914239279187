import { useContext, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PRsTable } from '../components/prs-table'
import { RepositoriesNavigator } from '../components/repositories-navigator'
import { ThemeContext } from '../utils/use-theme'
import {
  useGetInstallations,
  useGetPixeebotPullRequests,
  useGetRepositories,
  useGetUser,
} from '../utils/user-platform-api-hooks'
import './repository-page.css'

export const RepositoryPage = () => {
  const { theme } = useContext(ThemeContext)

  const navigate = useNavigate()
  const { accountLogin, repositoryName } = useParams() as { accountLogin: string; repositoryName: string }

  const { data: user } = useGetUser()
  const { data: installations } = useGetInstallations()
  const { data: repositories } = useGetRepositories(
    installations ? installations.map(installation => installation.id) : []
  )
  const { data: pullRequests } = useGetPixeebotPullRequests(
    installations.map(installation => installation.account.login)
  )

  useEffect(() => {
    if (!installations) return
    if (!repositories) return
    const currentInstallation = installations.find(installation => installation.account.login === accountLogin)
    if (!currentInstallation) navigate('/')
    const currentRepository = repositories.find(repository => repository.name === repositoryName)
    if (!currentRepository) navigate('/')
  }, [installations])

  const currentRepository = repositories?.find(item => item.name === repositoryName)
  const repositoryPRs =
    (currentRepository &&
      pullRequests.filter(
        pr => pr.repository_url === `https://api.github.com/repos/${currentRepository.full_name}` && pr.state === 'open'
      )) ??
    []

  if (!user || !installations || !currentRepository) return <></>

  return (
    <main className="container px-4 py-5" id={theme}>
      <nav aria-label="breadcrumb" style={{ marginBottom: 40 }}>
        <ol className="breadcrumb">
          <li className="breadcrumb-item inactive">
            <Link className="small" to="/installations">
              Installations
            </Link>
          </li>
          <li className="breadcrumb-item inactive">
            <Link className="small" to={`/context/${accountLogin}`}>
              {accountLogin}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Link className="small" to="">
              {repositoryName}
            </Link>
          </li>
        </ol>
      </nav>
      <div className="row">
        <div className="col-lg-3 d-flex flex-column">
          <img
            src={
              currentRepository && currentRepository.owner?.avatar_url
                ? currentRepository && currentRepository.owner?.avatar_url
                : ''
            }
            width={200}
            height={200}
            alt=""
            style={{ borderRadius: '16px', marginBottom: 10 }}
          />
          <RepositoriesNavigator
            repositories={repositories ? repositories : []}
            currentRepository={currentRepository}
            theme={theme}
          />
          <p className="paragraph" style={{ color: theme == 'dark' ? 'var(--neutral30)' : 'var(--neutral50)' }}>
            Repository
          </p>
        </div>
        <div className="col-lg-9">
          <PRsTable openPRs={repositoryPRs} theme={theme} />
        </div>
      </div>
    </main>
  )
}
