import { DateTime } from 'luxon'
import { useParams } from 'react-router-dom'
import {
  useGetFindings,
  useGetInstallations,
  useGetRepositories,
  useGetScanAnalysis,
} from '../utils/user-platform-api-hooks'
import { Finding, Installation, RepositoryWithInstallationId, ScanAnalysis } from '../utils/user-platform-api-schemas'

export function AnalysisDetailsPage() {
  const { installationId, repositoryId, analysisId, scanId } = useParams()
  if (!installationId || !repositoryId || !analysisId || !scanId) {
    throw new Error('Missing required parameters: installationId, repositoryId, analysisId, or scanId')
  }

  const { data: installations } = useGetInstallations()
  const installation = installations?.find(installation => installation.id === Number(installationId))
  const { data: repositories } = useGetRepositories(installation?.id ? [installation.id] : [])
  const { data: scanAnalysis } = useGetScanAnalysis({ analysisId, scanId })
  const { data: findings } = useGetFindings({ analysisId, scanId })

  return (
    <AnalysisDetailsComponent
      repositoryId={repositoryId}
      installation={installation}
      repositories={repositories}
      scanAnalysis={scanAnalysis}
      findings={findings}
    />
  )
}

export function AnalysisDetailsComponent({
  repositoryId,
  installation,
  repositories,
  scanAnalysis,
  findings,
}: {
  repositoryId: string
  installation?: Installation
  repositories: RepositoryWithInstallationId[]
  scanAnalysis?: ScanAnalysis
  findings: Finding[]
}) {
  return (
    <div>
      <h1>Analysis Details</h1>
      <p>{installation?.account.login}</p>
      <p>{repositories?.find(repo => repo.id === Number(repositoryId))?.name}</p>
      <p>{scanAnalysis?.branch}</p>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Severity</th>
          </tr>
        </thead>
        <tbody>
          {findings?.map(finding => (
            <tr key={finding.rule + finding.title}>
              <td>
                {finding.title} {finding.rule}
              </td>
              <td>{finding.severity}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {scanAnalysis?.tool_format.tool === 'SNYK' && (
        <>
          <img src="/snyk-logo.png" alt="Snyk logo" />
          <a href="https://snyk.io/test">View in Snyk</a>
        </>
      )}
      {scanAnalysis && (
        <>
          <p>{DateTime.fromISO(scanAnalysis.uploaded_at).toFormat('M/d/yy')}</p>
          <p>{DateTime.fromISO(scanAnalysis.uploaded_at).toFormat('hh:mm:ss a')}</p>
        </>
      )}
    </div>
  )
}
