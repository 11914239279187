import { match } from 'ts-pattern'
import * as styles from './importance-badge.css.ts'

type ImportanceBadgeProps = {
  variant: 'LOW' | 'MEDIUM' | 'HIGH' | 'UNKNOWN'
  style?: any
}

export function ImportanceBadge({ variant, ...props }: ImportanceBadgeProps) {
  if (variant === 'UNKNOWN') return <></>

  const text = match(variant)
    .with('LOW', () => 'Low')
    .with('MEDIUM', () => 'Med')
    .with('HIGH', () => 'High')
    .exhaustive()

  return (
    <span className={styles.badge[variant]} {...props}>
      {text}
    </span>
  )
}
