import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { ChevronLeftIcon } from '@radix-ui/react-icons'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useContext, useState } from 'react'
import { typography } from '../main.css.ts'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import { ThemeContext } from '../utils/use-theme'
import { usePutOwnerPreferenceMutation } from '../utils/user-platform-api-hooks'
import { CodemodPreference } from '../utils/user-platform-api-schemas'
import * as styles from './codemod-preference-configuration-dialog-onboarding.css.ts'
import { DefaultButton } from './default-button'
import { GhostButton } from './ghost-button'

export function CodemodPreferenceConfigurationDialogOnboarding({
  installationWithoutConfiguration,
  setIsOnboardingComplete,
  setIsCheckInstallationsBreadcrumb,
  setIsCheckInstallationsBanner,
}) {
  const queryClient = useQueryClient()
  const [onboardingStep, setOnboardingStep] = useState<'welcome' | 'configuration' | 'skip'>('welcome')
  const [codemodPreference, setCodemodPreference] = useState<CodemodPreference>('security+tools')
  const { theme } = useContext(ThemeContext)
  const updateOwnerPreferenceMutation = usePutOwnerPreferenceMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['owner-preferences'] }),
  })
  const environmentData = useEnvironmentData()

  return (
    <AlertDialog.Root open={true}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className={styles.alertDialogOverlay} />
        {onboardingStep === 'welcome' && (
          <AlertDialog.Content className={styles.alertDialogContent}>
            <div className={styles.dialogTop}>
              <span>GET STARTED</span>
              <span style={{ textTransform: 'lowercase' }}>1 of 2</span>
            </div>
            <AlertDialog.Title className={styles.welcome}>Welcome! 🎉</AlertDialog.Title>
            <AlertDialog.Description className={styles.alertDialogDescription} asChild>
              <div>
                <p className={styles.paragraph}>
                  Thank you for installing. {environmentData.githubAppName} is still in the process of running its first
                  analysis, so check back in a bit for a status update. In the meantime...
                </p>
                <div>
                  <h4 className={styles.connectYourTools}>Connect your tools</h4>
                  <p className={styles.paragraph}>
                    You’ll get the most value by integrating with security tools you may already use. Check out our{' '}
                    <a href="https://docs.pixee.ai/code-scanning-tools/overview" className={styles.link}>
                      Docs
                    </a>{' '}
                    to see how you can easily configure {environmentData.githubAppName} to address findings from the
                    following:
                  </p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '96px' }}>
                  <a href="https://docs.pixee.ai/code-scanning-tools/sonar/">
                    <img
                      src={theme === 'dark' ? 'sonar.png' : 'sonar-light-mode.png'}
                      alt="Sonar"
                      className={styles.toolImage}
                    />
                  </a>
                  <a href="https://docs.pixee.ai/code-scanning-tools/codeql/">
                    <img src={'codeql.png'} alt="CodeQL" className={styles.toolImage} />
                  </a>
                  <a href="https://docs.pixee.ai/code-scanning-tools/semgrep/">
                    <img
                      src={theme === 'dark' ? 'semgrep.png' : 'semgrep-light-mode.png'}
                      alt="Semgrep"
                      className={styles.toolImage}
                    />
                  </a>
                </div>
              </div>
            </AlertDialog.Description>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <DefaultButton onClick={() => setOnboardingStep('configuration')}>Next</DefaultButton>
            </div>
          </AlertDialog.Content>
        )}
        {onboardingStep === 'configuration' && (
          <AlertDialog.Content className={styles.alertDialogContent}>
            <div className={styles.dialogTop}>
              <span>GET STARTED</span>
              <span style={{ textTransform: 'lowercase' }}>2 of 2</span>
            </div>
            <AlertDialog.Title className={styles.welcome}>Set default preference</AlertDialog.Title>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
              <AlertDialog.Description asChild>
                <div>
                  <p className={styles.paragraph} style={{ margin: '2px' }}>
                    {environmentData.githubAppName} fixes issues across a spectrum of use cases. Help us understand
                    what’s most relevant to you, and we’ll create the appropriate default. You can always change or
                    customize this later.{' '}
                  </p>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <a
                      href="https://docs.pixee.ai/configuring"
                      target="_blank"
                      className={classNames(styles.link, typography.paragraph)}
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </AlertDialog.Description>
              <div>
                <p className={typography.smallBold} style={{ margin: '8px' }}>
                  Account
                </p>
                <div className={classNames(typography.paragraph, styles.accountBox)}>
                  <img className={styles.avatar} src={installationWithoutConfiguration?.account.avatar_url} />
                  <p style={{ margin: 0, verticalAlign: 'center' }}>
                    {installationWithoutConfiguration?.account.login}
                  </p>
                </div>
              </div>
              <form>
                <RadioGroup.Root
                  defaultValue={codemodPreference}
                  onValueChange={value =>
                    // @ts-expect-error
                    setCodemodPreference(value)
                  }
                  aria-label="Codemod configuration"
                >
                  <div className={styles.tilesContainer}>
                    <RadioGroup.Item value="everything" id="tile1">
                      <div className={styles.tile}>
                        <label htmlFor="tile1" className={styles.tileLabel}>
                          <p className={styles.tileTitle}>Everything</p>
                          <p className={styles.tileDescription}>
                            Give me all security and quality recommendations from {environmentData.githubAppName}, as
                            well as fixes for my connected security tools
                          </p>
                        </label>
                      </div>
                    </RadioGroup.Item>
                    <RadioGroup.Item value="security+tools" id="tile2">
                      <div className={styles.tile}>
                        <label htmlFor="tile2" className={styles.tileLabel}>
                          <p
                            className={styles.tileTitle}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}
                          >
                            Security + Tools<span className={typography.extraSmallItalic}>Default</span>
                          </p>
                          <p className={styles.tileDescription} style={{ width: '219px' }}>
                            I prefer to focus on {environmentData.githubAppName}'s security recommendations and my
                            security tool fixes{' '}
                          </p>
                        </label>
                      </div>
                    </RadioGroup.Item>
                    <RadioGroup.Item value="tools-only" id="tile3">
                      <div className={styles.tile}>
                        <label htmlFor="tile3" className={styles.tileLabel}>
                          <p className={styles.tileTitle}>Tools only</p>
                          <p className={styles.tileDescription} style={{ width: '243px' }}>
                            Just fix the findings from my connected security tools and omit{' '}
                            {environmentData.githubAppName} recommendations for now
                          </p>
                        </label>
                      </div>
                    </RadioGroup.Item>
                    <RadioGroup.Item value="custom" id="tile4">
                      <div className={styles.tile}>
                        <label htmlFor="tile4" className={styles.tileLabel}>
                          <p className={styles.tileTitle}>Custom</p>
                          <p className={styles.tileDescription}>I'll create a default from scratch</p>
                        </label>
                      </div>
                    </RadioGroup.Item>
                  </div>
                </RadioGroup.Root>
              </form>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a
                  href="https://tally.so/r/3qbdr5"
                  target="_blank"
                  className={classNames(styles.link, typography.small)}
                >
                  Give feedback
                </a>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <GhostButton
                  type="secondary"
                  onClick={() => {
                    setOnboardingStep('welcome')
                  }}
                >
                  <ChevronLeftIcon />
                  Previous
                </GhostButton>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <GhostButton
                    type="secondary"
                    onClick={() => {
                      setOnboardingStep('skip')
                    }}
                  >
                    Skip
                  </GhostButton>
                  <AlertDialog.Action asChild>
                    <DefaultButton
                      onClick={() => {
                        if (installationWithoutConfiguration)
                          updateOwnerPreferenceMutation.mutate({
                            ownerId: installationWithoutConfiguration.account.id,
                            codemodPreference,
                          })
                        setIsOnboardingComplete(true)
                        setIsCheckInstallationsBanner(true)
                        setIsCheckInstallationsBreadcrumb(true)
                      }}
                    >
                      Set default
                    </DefaultButton>
                  </AlertDialog.Action>
                </div>
              </div>
            </div>
          </AlertDialog.Content>
        )}
        {onboardingStep === 'skip' && (
          <AlertDialog.Content
            className={styles.alertDialogContent}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '554px' }}
          >
            <span className={styles.dialogTop}>GET STARTED</span>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', textAlign: 'center' }}>
              <AlertDialog.Title className={typography.h5} style={{ margin: 0 }}>
                Skip this?
              </AlertDialog.Title>
              <AlertDialog.Description asChild>
                <p className={typography.paragraph} style={{ margin: 0 }}>
                  No problem, we'll just use our standard default for now{' '}
                  <span className={typography.paragraphItalic}>(Security + Tools)</span>. You
                  <br /> can always change your preference later, just check out our{' '}
                  <a href="https://docs.pixee.ai/configuring" target="_blank" className={styles.link}>
                    Docs
                  </a>{' '}
                  to learn how.
                </p>
              </AlertDialog.Description>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a
                  href="https://tally.so/r/3qbdr5"
                  target="_blank"
                  className={classNames(styles.link, typography.small)}
                >
                  Give feedback
                </a>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <GhostButton
                type="secondary"
                onClick={() => {
                  setOnboardingStep('configuration')
                }}
              >
                <ChevronLeftIcon />
                Previous
              </GhostButton>
              <AlertDialog.Action asChild>
                <DefaultButton
                  onClick={() => {
                    if (installationWithoutConfiguration)
                      updateOwnerPreferenceMutation.mutate({
                        ownerId: installationWithoutConfiguration.account.id,
                        codemodPreference: 'security+tools',
                      })
                    setIsOnboardingComplete(true)
                    setIsCheckInstallationsBanner(true)
                    setIsCheckInstallationsBreadcrumb(true)
                  }}
                >
                  Done
                </DefaultButton>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        )}
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
