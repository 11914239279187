import classNames from 'classnames'
import { ReactNode, forwardRef } from 'react'
import * as styles from './outline-button.css.ts'

type OutlineButtonProps = {
  state: 'active' | 'inactive'
  size?: 'default' | 'small'
  className?: string
  children: ReactNode
  onClick?: (event: any) => void
  onKeyDown?: (event: any) => void
}

export const OutlineButton = forwardRef(
  ({ state, size = 'default', className, children, ...props }: OutlineButtonProps, forwardedRef: any) => {
    return (
      <button
        className={classNames(className, styles.button({ state, size }))}
        onMouseDown={e => {
          e.preventDefault()
        }}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </button>
    )
  }
)
