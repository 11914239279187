export const toggleFilter =
  (columnId: string) =>
  (previousFilters: { id: string; value: string[] }[], filterValue): { id: string; value: string[] }[] => {
    const previousFilter = previousFilters.find(({ id }) => columnId === id)
    if (!previousFilter) return [...previousFilters, { id: columnId, value: [filterValue] }]

    const previousValue = previousFilter.value.find(value => value === filterValue)
    if (!previousValue)
      return [
        ...previousFilters.filter(({ id }) => id !== columnId),
        { id: columnId, value: [...previousFilter.value, filterValue] },
      ]
    if (previousFilter.value.length === 1) return [...previousFilters.filter(({ id }) => id !== columnId)]

    return [
      ...previousFilters.filter(({ id }) => id !== columnId),
      { id: columnId, value: previousFilter.value.filter(value => value !== filterValue) },
    ]
  }
