import * as CheckboxRadix from '@radix-ui/react-checkbox'
import { CheckIcon } from '@radix-ui/react-icons'
import { forwardRef } from 'react'
import * as styles from './checkbox.css.ts'

type CheckboxProps = {
  state?: 'default' | 'disabled'
  checked?: boolean
  Indicator?: React.ElementType
  onCheckedChange?: (event: any) => void
}

export const Checkbox = forwardRef(
  (
    { state = 'default', checked, Indicator, onCheckedChange = () => {}, ...props }: CheckboxProps,
    forwardedRef: any
  ) => {
    return (
      <CheckboxRadix.Root
        className={styles.checkboxRoot[state]}
        checked={checked}
        onCheckedChange={onCheckedChange}
        disabled={state === 'disabled'}
        onMouseDown={e => {
          e.preventDefault()
        }}
        {...props}
        ref={forwardedRef}
      >
        {Indicator ? (
          <Indicator className={styles.checkboxIndicator}>
            <CheckIcon className={styles.checkboxIcon} />
          </Indicator>
        ) : (
          <CheckboxRadix.Indicator className={styles.checkboxIndicator}>
            <CheckIcon className={styles.checkboxIcon} />
          </CheckboxRadix.Indicator>
        )}
      </CheckboxRadix.Root>
    )
  }
)
