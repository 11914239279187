import * as Tooltip from '@radix-ui/react-tooltip'
import { useState } from 'react'

// Necessary to enable tooltip on click on mobile since Radix designers won't add this functionality
// https://github.com/radix-ui/primitives/issues/955
export default function TooltipWithClickToOpen({ children }) {
  const [open, setOpen] = useState(false)

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={open} delayDuration={0} onOpenChange={setOpen}>
        <span
          onClick={() => setOpen(true)}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center ' }}
        >
          {children}
        </span>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
