const BackArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="chevron-left">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0314 2.46888C17.1012 2.53854 17.1566 2.62131 17.1944 2.71243C17.2322 2.80354 17.2517 2.90122 17.2517 2.99988C17.2517 3.09853 17.2322 3.19621 17.1944 3.28733C17.1566 3.37844 17.1012 3.46121 17.0314 3.53088L8.56086 11.9999L17.0314 20.4689C17.1722 20.6097 17.2513 20.8007 17.2513 20.9999C17.2513 21.199 17.1722 21.39 17.0314 21.5309C16.8905 21.6717 16.6995 21.7508 16.5004 21.7508C16.3012 21.7508 16.1102 21.6717 15.9694 21.5309L6.96936 12.5309C6.89952 12.4612 6.8441 12.3784 6.80629 12.2873C6.76849 12.1962 6.74902 12.0985 6.74902 11.9999C6.74902 11.9012 6.76849 11.8035 6.80629 11.7124C6.8441 11.6213 6.89952 11.5385 6.96936 11.4689L15.9694 2.46888C16.039 2.39903 16.1218 2.34362 16.2129 2.30581C16.304 2.268 16.4017 2.24854 16.5004 2.24854C16.599 2.24854 16.6967 2.268 16.7878 2.30581C16.8789 2.34362 16.9617 2.39903 17.0314 2.46888Z"
          fill="#FBFAFB"
        />
      </g>
    </svg>
  )
}
export default BackArrow
