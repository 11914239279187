import { createContext, useEffect, useState } from 'react'

export type ThemeContextType = {
  theme: 'light' | 'dark'
  handleToggleTheme: () => void
}

export const ThemeContext = createContext<ThemeContextType>({ theme: 'dark', handleToggleTheme: () => {} })

export const useTheme = () => {
  const [theme, setTheme] = useState<ThemeContextType['theme']>('dark')

  useEffect(() => {
    const storedTheme = localStorage.getItem('currentTheme')
    if (storedTheme === 'light') {
      setTheme('light')
      document.documentElement.setAttribute('data-bs-theme', 'light')
    } else if (!storedTheme) {
      localStorage.setItem('currentTheme', 'dark')
    }
  }, [])

  const handleToggleTheme = () => {
    setTheme(preTheme => (preTheme === 'dark' ? 'light' : 'dark'))
    localStorage.setItem('currentTheme', theme === 'dark' ? 'light' : 'dark')
    document.documentElement.setAttribute('data-bs-theme', theme === 'dark' ? 'light' : 'dark')
  }

  return { theme, setTheme, handleToggleTheme }
}
