import { useState } from 'react'
import { PullRequest, PullRequestWithImportance } from '../utils/user-platform-api-schemas'
import { ImportanceBadge } from './importance-badge'
import { PaginationControls } from './pagination-controls'
import { PRStatusBadge } from './pr-status-badge'
import * as styles from './simple-issues-table.css.ts'
import { TableBodySkeleton } from './table-body-skeleton'

type SimpleIssuesTableProps = {
  title: string
  pullRequests?: PullRequestWithImportance[]
}

export function SimpleIssuesTable({ title, pullRequests }: SimpleIssuesTableProps) {
  let [pageIndex, setPageIndex] = useState<number>(0)
  const pageSize = 5
  if (pullRequests) {
    let maxIndex = Math.ceil(pullRequests.length / pageSize) - 1
    maxIndex = maxIndex > 0 ? maxIndex : 0
    if (pageIndex > maxIndex) setPageIndex(maxIndex)
  }

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.tableHeading}>
          {title} ({pullRequests?.length ?? 0})
        </h2>
      </div>
      <table className={styles.table} style={{ flexGrow: pullRequests?.length === 0 ? '1' : 'unset' }}>
        {!pullRequests ? (
          <TableBodySkeleton rowCount={5} columnCount={3} rowHeightInPixels={64} />
        ) : pullRequests.length === 0 ? (
          <tbody>
            <tr key={0}>
              <td colSpan={3} className={styles.emptyStateRow}>
                No data yet, stay tuned
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {pullRequests.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize).map(pullRequest => (
              <PRRow key={pullRequest.id} pullRequest={pullRequest} />
            ))}
          </tbody>
        )}
      </table>
      {pullRequests && pullRequests.length !== 0 && (
        <>
          <div style={{ flexGrow: 1 }}></div>
          <div className={styles.paginationContainer}>
            <PaginationControls
              tableLabel={title}
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={pullRequests.length}
              onNextPage={() => setPageIndex(pageIndex => pageIndex + 1)}
              onPreviousPage={() => setPageIndex(pageIndex => pageIndex - 1)}
              onChangePageSize={undefined}
            />
          </div>
        </>
      )}
    </div>
  )
}

type PRRowProps = {
  pullRequest: PullRequestWithImportance
}

const PRRow = ({ pullRequest }: PRRowProps) => {
  const state = calculatePullRequestState(pullRequest)
  const recentDate =
    state === 'open'
      ? `Opened at ${relativeTime(pullRequest.created_at)}`
      : state === 'closed'
        ? `Closed at ${relativeTime(pullRequest.closed_at)}`
        : `Merged at ${relativeTime(pullRequest.pull_request!.merged_at!)}`

  return (
    <tr className={styles.row}>
      <td className={styles.firstTD}>
        <ImportanceBadge variant={pullRequest.importance} />
      </td>
      <td className={styles.middleTD}>
        <a className={styles.pullRequestName} href={pullRequest.html_url} target="_blank">
          {pullRequest.title}
        </a>
        <br />
        <a
          className={styles.pullRequestLocation}
          href={pullRequest.repository_url.replace('api.github.com/repos', 'github.com')}
          target="_blank"
        >
          {`${pullRequest.repository_url.split('/')[4]} / ${pullRequest.repository_url.split('/')[5]}`}
        </a>
        <span className={styles.pullRequestDate}>
          #{pullRequest.number} {recentDate}
        </span>
      </td>
      <td className={styles.lastTD}>
        <a href={pullRequest.html_url} target="_blank">
          <PRStatusBadge variant={state} />
        </a>
      </td>
    </tr>
  )
}

export const calculatePullRequestState = (pullRequest: PullRequest): 'open' | 'merged' | 'closed' => {
  if (pullRequest.state === 'open') return 'open'
  if (pullRequest.pull_request?.merged_at !== null) return 'merged'
  return 'closed'
}

function relativeTime(timestamp) {
  const now = new Date().getTime()
  const secondsAgo = Math.floor((now - new Date(timestamp).getTime()) / 1000)

  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60)
    return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600)
    return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`
  } else {
    const daysAgo = Math.floor(secondsAgo / 86400)
    return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`
  }
}
