import { DateTime, Interval } from 'luxon'
import { match } from 'ts-pattern'
import type { PRsAreaChartData } from '../components/prs-area-chart'
import { AnalysisStatusLog, PullRequest } from './user-platform-api-schemas'

type toPRChartData = (
  pullRequests: PullRequest[],
  analyses: AnalysisStatusLog[],
  daysFromToday: number,
  nowIsoTime?: string,
  timezone?: string
) => PRsAreaChartData
export const toPRChartData: toPRChartData = (
  pullRequests,
  analyses,
  daysFromToday,
  nowIsoTime = new Date().toISOString(),
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const labels = createDateLabels(daysFromToday, nowIsoTime, timezone)
  const events = prsToEvents(pullRequests)
  const data = labels.map(({ startOfDayLocal, endOfDayLocal, label }) => {
    const counts = events.reduce(
      (counts, { type, timestamp }) => {
        const eventTime = DateTime.fromISO(timestamp)
        const isEventWithinDay = Interval.fromDateTimes(
          DateTime.fromISO(startOfDayLocal),
          DateTime.fromISO(endOfDayLocal)
        ).contains(eventTime)

        return match(type)
          .with('opened', () => {
            if (isEventWithinDay) return { ...counts, opened: counts.opened + 1 }
            return counts
          })
          .with('closed', () => {
            if (isEventWithinDay) return { ...counts, closed: counts.closed + 1 }
            return counts
          })
          .with('merged', () => {
            if (isEventWithinDay) return { ...counts, merged: counts.merged + 1 }
            return counts
          })
          .exhaustive()
      },
      { merged: 0, opened: 0, autoClosed: 0, closed: 0 }
    )
    const isAnalysisWithinDay = analyses.some(analysis => {
      const eventTime = DateTime.fromISO(analysis.created_at)
      const isEventWithinDay = Interval.fromDateTimes(
        DateTime.fromISO(startOfDayLocal),
        DateTime.fromISO(endOfDayLocal)
      ).contains(eventTime)

      return isEventWithinDay
    })
    if (isAnalysisWithinDay) return { name: label, ...counts, isAnalysisRun: true }
    return { name: label, ...counts }
  })

  return data
}

type createDateLabels = (
  days: number,
  nowIsoTime?: string,
  timezone?: string
) => { startOfDayLocal: string; endOfDayLocal: string; label: string }[]
export const createDateLabels: createDateLabels = (
  days,
  nowIsoTime = new Date().toISOString(),
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  const now = DateTime.fromISO(nowIsoTime, { zone: timezone })
  return [...Array(days).keys()]
    .map(x => x++)
    .reverse()
    .map(days => {
      const day = now.minus({ days })
      const startOfDay = day.startOf('day')
      const endOfDay = day.endOf('day')
      return {
        startOfDayLocal: startOfDay.toISO()!,
        endOfDayLocal: endOfDay.toISO()!,
        label: `${day.month}/${day.day}`,
      }
    })
}

type PREvent = { type: 'opened' | 'merged' | 'closed'; timestamp: string }
export const prsToEvents = (prs: PullRequest[]): PREvent[] => {
  const events: PREvent[] = []

  prs.forEach(pr => {
    events.push({ type: 'opened', timestamp: pr.created_at })
    if (pr.pull_request?.merged_at) {
      events.push({ type: 'merged', timestamp: pr.pull_request.merged_at! })
    }
    if (!pr.pull_request?.merged_at && pr.state === 'closed') {
      events.push({ type: 'closed', timestamp: pr.closed_at! })
    }
  })

  return events
}
