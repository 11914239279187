import classNames from 'classnames'
import * as styles from './default-button.css.ts'
import { Spinner } from './spinner'

type DefaultButtonProps = {
  children: React.ReactNode
  buttonType?: 'primary' | 'secondary'
  state?: 'default' | 'disabled' | 'loading'
  size?: 'default' | 'small'
  href?: string
  className?: string
  onClick?: (event: any) => void
  onKeyDown?: (event: any) => void
}

export function DefaultButton({
  children,
  buttonType = 'primary',
  state = 'default',
  size = 'default',
  href,
  className,
  onClick,
  onKeyDown,
}: Readonly<DefaultButtonProps>) {
  if (href)
    return (
      <a
        className={classNames(styles.button({ buttonType, state, size }), className)}
        onMouseDown={e => {
          e.preventDefault()
        }}
        href={state === 'disabled' ? '#' : href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        {children}
      </a>
    )

  return (
    <button
      className={classNames(styles.button({ buttonType, state, size }), className)}
      disabled={state === 'disabled' || state === 'loading'}
      onMouseDown={e => {
        e.preventDefault()
      }}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {state === 'loading' ? <Spinner /> : children}
    </button>
  )
}
