import classNames from 'classnames'
import * as styles from './ghost-button.css.ts'

type GhostButtonProps = {
  children: React.ReactNode
  type?: 'default' | 'secondary'
  state?: 'default' | 'disabled'
  size?: 'default' | 'small'
  href?: string
  className?: string
  onClick?: (event: any) => void
  onKeyDown?: (event: any) => void
}

export function GhostButton({
  children,
  type = 'default',
  state = 'default',
  size = 'default',
  href,
  className,
  onClick,
  onKeyDown,
}: GhostButtonProps) {
  if (href)
    return (
      <a
        className={classNames(styles.button({ type, state, size }), className)}
        href={state === 'disabled' ? '#' : href}
        onMouseDown={e => {
          e.preventDefault()
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        {children}
      </a>
    )

  return (
    <button
      className={classNames(styles.button({ type, state, size }), className)}
      disabled={state === 'disabled'}
      onMouseDown={e => {
        e.preventDefault()
      }}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {children}
    </button>
  )
}
