import { CheckIcon, Cross1Icon } from '@radix-ui/react-icons'
import {
  FilterFn,
  RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useLocalStorage } from '@uidotdev/usehooks'
import classNames from 'classnames'
import fuzzysort from 'fuzzysort'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { filterByLatestAnalyses } from '../utils/filter-by-latest-analyses'
import { toggleFilter } from '../utils/toggle-filter'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import {
  useGetInstallations,
  useGetPixeebotPullRequests,
  useGetRepositoriesAnalysisStatusLogs,
} from '../utils/user-platform-api-hooks'
import {
  AnalysisStatusLogsByRepositoryId,
  PullRequest,
  RepositoryActivation,
  RepositoryWithInstallationId,
} from '../utils/user-platform-api-schemas'
import { Checkbox } from './checkbox'
import { DefaultButton } from './default-button'
import { DropdownFilterSelect } from './dropdown-filter-select'
import { GhostButton } from './ghost-button'
import * as styles from './installations-table.css.ts'
import { PaginationControls } from './pagination-controls'
import { RepoStatus, calculateRepositoryStatus, sortStatuses } from './repository-status'
import { TableBodySkeleton } from './table-body-skeleton'
import { TextInput } from './text-input'

export type RepositoryWithData = {
  activation?: RepositoryActivation
  status: ReturnType<typeof calculateRepositoryStatus>
  pixeebotPRs: PullRequest[]
} & RepositoryWithInstallationId

type InstallationsTableProps = {
  repositories: RepositoryWithInstallationId[]
  repositoriesActivation: RepositoryActivation[]
  handleToggleRepositoriesActivation: (repositoriesIds: number[], toggleState?: boolean) => Promise<void>
}

export function InstallationsTable({
  repositories,
  repositoriesActivation,
  handleToggleRepositoriesActivation,
}: InstallationsTableProps) {
  let { data: installations } = useGetInstallations()
  let { data: allPixeebotPRs } = useGetPixeebotPullRequests(
    installations.map(installation => installation.account.login)
  )
  let { data: analysesByRepository } = useGetRepositoriesAnalysisStatusLogs(
    installations.map(installation => installation.id)
  )

  return (
    <Table
      data={repositories.map(toRepositoriesWithData(repositoriesActivation, allPixeebotPRs, analysesByRepository))}
      handleToggleRepositoriesActivation={handleToggleRepositoriesActivation}
    />
  )
}

type TableProps = {
  data: RepositoryWithData[]
  handleToggleRepositoriesActivation: (repositoriesIds: number[], toggleState?: boolean) => Promise<void>
}

function Table({ data, handleToggleRepositoriesActivation }: TableProps) {
  const columnHelper = createColumnHelper<RepositoryWithData>()
  const environmentData = useEnvironmentData()

  const columns = [
    columnHelper.display({
      id: 'checkbox',
      cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onCheckedChange={row.getToggleSelectedHandler()} />,
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          onCheckedChange={checked => table.getToggleAllRowsSelectedHandler()({ target: { checked } })}
        />
      ),
    }),
    columnHelper.accessor('full_name', {
      cell: ({ row }) => <RepoLocation repositoryWithData={row.original} />,
      header: ({}) => <span>LOCATION</span>,
    }),
    columnHelper.accessor('status.status', {
      id: 'status',
      cell: ({ row }) => (
        <RepoStatus
          repository={row.original}
          repositoryPixeebotOpenPRs={row.original.pixeebotPRs.filter(pr => pr.state === 'open')}
          statusMetadata={row.original.status}
        />
      ),
      header: () => (
        <>
          <img src={environmentData.logoIconHref} style={{ height: '24px', marginRight: '8px' }} />
          <span title="pixeebot analysis status of the latest code on your default branch.">Status</span>
        </>
      ),
      // @ts-expect-error
      filterFn: 'select',
    }),
    columnHelper.accessor(
      ({ activation }) => ((activation?.user_activated && activation?.pixee_approved) ?? false).toString(),
      {
        id: 'active',
        cell: ({ row }) => (
          <RepoToggleActivationButton
            repositoryName={row.original.name}
            repositoryActivation={row.original.activation}
            handleToggleRepositoriesActivation={handleToggleRepositoriesActivation}
          />
        ),
        header: () => <span>ACTIVE</span>,
        // @ts-expect-error
        filterFn: 'select',
      }
    ),
  ]

  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string[] }[]>([])

  const toggleAnalysisColumnFilter = (value: string) => () => {
    setColumnFilters(previousFilters => toggleFilter('status')(previousFilters, value))
  }
  const toggleStatusColumnFilter = (value: string) => () => {
    setColumnFilters(previousFilters => toggleFilter('active')(previousFilters, value))
  }

  const [pagination, setPagination] = useLocalStorage('ui-state/pagination', {
    pageIndex: 0,
    pageSize: 10,
  })

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      columnFilters,
      pagination,
      rowSelection,
    },
    globalFilterFn: fuzzyFilter,
    filterFns: {
      select: selectFilter,
    },
    getRowId: row => String(row.id),
  })

  data.sort(({ status: repositoryStatusA }, { status: repositoryStatusB }) =>
    sortStatuses(repositoryStatusA.status, repositoryStatusB.status)
  )

  return (
    <div className={styles.topLevelContainer}>
      <div className={styles.tableControls}>
        <div>
          <h4 className={styles.tableHeading}>
            Installations{' '}
            <span className={styles.installationsCount}>({table.getPrePaginationRowModel().rows?.length})</span>
          </h4>
          <p className={styles.tableDescription}>Locations where {environmentData.githubAppName} has been installed.</p>
        </div>

        <div className={styles.tableControlsRight}>
          <TextInput value={globalFilter ?? ''} onChange={value => setGlobalFilter(String(value))} size="small" />
          <DropdownFilterSelect
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            toggleAnalysisColumnFilter={toggleAnalysisColumnFilter}
            toggleStatusColumnFilter={toggleStatusColumnFilter}
          />
        </div>
      </div>
      <table className={styles.table}>
        <thead className={styles.tableHead}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    className={classNames(styles.columnHeader, styles.cell, styles.installationsTableCell)}
                  >
                    <div className={styles.center}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        {data.length === 0 ? (
          <TableBodySkeleton rowCount={7} columnCount={columns.length} rowHeightInPixels={43} />
        ) : (
          <tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <tr key={row.id} className={styles.row}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className={classNames(styles.cell, styles.installationsTableCell)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td className={classNames(styles.cell, styles.emptyStateCell)} colSpan={columns.length}>
                  <div className={styles.emptyStateText}>No results found</div>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      <div className={styles.tableFooter}>
        <a
          href={`https://github.com/apps/pixeebot/installations/new`}
          target="_blank"
          className="btn-no-border-outline small-bold"
          data-toggle="tooltip"
          data-placement="top"
        >
          <span className="plus-icon">+</span> Add installation
        </a>
        <PaginationControls
          tableLabel="Installations"
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          rowCount={table.getPrePaginationRowModel().rows?.length}
          onNextPage={() => setPagination(pagination => ({ ...pagination, pageIndex: pagination.pageIndex + 1 }))}
          onPreviousPage={() => setPagination(pagination => ({ ...pagination, pageIndex: pagination.pageIndex - 1 }))}
          onChangePageSize={value => setPagination({ pageIndex: 0, pageSize: value })}
        />
      </div>
      {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
        <div className={styles.bulkActionPromptContainer}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <p className={styles.selectedBulkActionText}>
              <CheckIcon className={styles.checkIconBulkAction} />
              {Object.keys(rowSelection).length} selected
            </p>
            <GhostButton size="small" type="secondary" onClick={() => setRowSelection({})}>
              <Cross1Icon width={13} height={13} className={styles.clearButtonIcon} />
              Clear
            </GhostButton>
          </div>
          <div style={{ display: 'flex', gap: '12px' }}>
            <GhostButton
              size="small"
              type="secondary"
              onClick={() => {
                handleToggleRepositoriesActivation(
                  Object.keys(rowSelection).map(x => Number(x)),
                  false
                )
                setRowSelection({})
              }}
              state={
                data
                  .filter(repository => Object.keys(rowSelection).includes(String(repository.id)))
                  .some(repository => repository.activation?.user_activated)
                  ? 'default'
                  : 'disabled'
              }
            >
              Disable all
            </GhostButton>
            <DefaultButton
              size="small"
              onClick={() => {
                handleToggleRepositoriesActivation(
                  Object.keys(rowSelection).map(x => Number(x)),
                  true
                )
                setRowSelection({})
              }}
              state={
                data
                  .filter(repository => Object.keys(rowSelection).includes(String(repository.id)))
                  .some(repository => !repository.activation?.user_activated)
                  ? 'default'
                  : 'disabled'
              }
            >
              Activate all
            </DefaultButton>
          </div>
        </div>
      )}
    </div>
  )
}

type RepoLocationProps = {
  repositoryWithData: RepositoryWithData
}

function RepoLocation({ repositoryWithData }: RepoLocationProps) {
  let isActive =
    (repositoryWithData.activation?.pixee_approved && repositoryWithData.activation?.user_activated) ?? false
  isActive = isActive || repositoryWithData.pixeebotPRs.length > 0
  const activeVariant = isActive ? 'active' : 'inactive'

  return (
    <>
      <img
        src={repositoryWithData.owner.avatar_url}
        style={{ width: '24px', height: '24px', borderRadius: '4px', marginRight: '8px' }}
      />
      <Link
        className={classNames(styles.link, styles.locationText[activeVariant])}
        style={{ pointerEvents: isActive ? undefined : 'none' }}
        aria-disabled={isActive}
        to={`/context/${repositoryWithData.owner.login}`}
      >
        {repositoryWithData.owner.login}
      </Link>
      <span className={styles.locationText[activeVariant]}> / </span>
      <Link
        className={classNames(styles.link, styles.locationText[activeVariant])}
        style={{ pointerEvents: isActive ? undefined : 'none' }}
        aria-disabled={isActive}
        to={`/context/${repositoryWithData.full_name}`}
      >
        {repositoryWithData.name}
      </Link>
    </>
  )
}

type RepoToggleActivationButtonProps = {
  repositoryName: string
  repositoryActivation?: RepositoryActivation
  handleToggleRepositoriesActivation: (repositoriesIds: number[], toggleState?: boolean) => Promise<void>
}

function RepoToggleActivationButton({
  repositoryActivation,
  handleToggleRepositoriesActivation,
}: RepoToggleActivationButtonProps) {
  return (
    <>
      {repositoryActivation ? (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={repositoryActivation.user_activated}
            onChange={() => handleToggleRepositoriesActivation([repositoryActivation.repository_id])}
          />
        </div>
      ) : (
        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" role="switch" checked disabled />
        </div>
      )}
    </>
  )
}

const toRepositoriesWithData =
  (
    repositoriesActivation: RepositoryActivation[],
    allPixeebotPRs: PullRequest[],
    analysesByRepository: AnalysisStatusLogsByRepositoryId[]
  ) =>
  (repository: RepositoryWithInstallationId): RepositoryWithData => {
    const repositoryActivation = repositoriesActivation.find(
      repositoryActivation => repositoryActivation.repository_id === repository.id
    )

    const repositoryPixeebotPRs = allPixeebotPRs.filter(
      pullRequest => pullRequest.repository_url === `https://api.github.com/repos/${repository.full_name}`
    )

    const analyses =
      analysesByRepository.find(({ repository_id }) => repository_id === repository.id)?.analysis_status_logs ?? []

    const repositoryStatus = calculateRepositoryStatus({
      repositoryPixeebotOpenPRs: repositoryPixeebotPRs.filter(pr => pr.state === 'open'),
      analyses: analyses.filter(filterByLatestAnalyses()),
      repositoryActivation,
    })

    return {
      ...repository,
      activation: repositoryActivation,
      pixeebotPRs: repositoryPixeebotPRs,
      status: repositoryStatus,
    }
  }

const fuzzyFilter: FilterFn<any> = (row, columnId, value) => {
  const results = fuzzysort.go(value, [row.getValue(columnId)])
  return results.length > 0
}

const selectFilter: FilterFn<any> = (row, columnId, value) => {
  const selectedValues = value
  const columnValue: any = row.getValue(columnId)

  return selectedValues.some(value => value === columnValue)
}
