import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom'
import './context-navigator.css'

import { Repository } from '../utils/user-platform-api-schemas'
import BackArrow from './svg/back-arrow'
import BackArrowBlack from './svg/back-arrow-black'

type RepositoriesNavigatorProps = {
  repositories: Repository[]
  currentRepository: Repository
  theme: 'dark' | 'light'
}

export const RepositoriesNavigator = ({ repositories, currentRepository, theme }: RepositoriesNavigatorProps) => {
  if (!repositories || repositories.length <= 1) return <></>

  return (
    <Dropdown className="drop-down">
      <Dropdown.Toggle variant="tertiary" className="h-6 px-0 selected-account-text">
        {currentRepository.name.length > 15 ? currentRepository.name.slice(0, 15 - 3) + '...' : currentRepository.name}
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="end"
        className="drop-down-menu"
        // @ts-ignore
        style={{ '--bs-dropdown-link-active-bg': '#38393a' }}
      >
        <Dropdown.Item
          key="home"
          as={Link}
          to={`/context/${currentRepository?.owner.login}`}
          className="navigation-item"
        >
          <div className="navigation-item-container">
            <div>
              {theme === 'dark' ? <BackArrow /> : <BackArrowBlack />}
              <span className="paragraph navigation-text">{currentRepository.owner.login} </span>
            </div>
          </div>
        </Dropdown.Item>
        {repositories.map(repository => (
          <Dropdown.Item
            key={repository.id}
            className="context-nav-item"
            as={Link}
            to={`/context/${currentRepository?.owner.login}/${repository.name}`}
          >
            <img src={repository.owner.avatar_url} width="20px" />{' '}
            {repository.name.length > 15 ? repository.name.slice(0, 15 - 3) + '...' : repository.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
