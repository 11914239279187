import { AnalysisStatusLog } from './user-platform-api-schemas'

export const filterByLatestAnalyses =
  (latestAnalyses: { [key: string]: Date } = {}) =>
  (analysis: AnalysisStatusLog): boolean => {
    const currentDate = new Date(analysis.created_at)
    const latestDate = latestAnalyses[analysis.analysis_id]

    if (!latestDate) {
      latestAnalyses[analysis.analysis_id] = currentDate
      return true
    }

    if (currentDate > latestDate) {
      latestAnalyses[analysis.analysis_id] = currentDate
      return true
    }

    return false
  }
