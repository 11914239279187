import { MarkGithubIcon } from '@primer/octicons-react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons'
import * as Select from '@radix-ui/react-select'
import classnames from 'classnames'
import React from 'react'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import { Installation, RepositoryWithInstallationId } from '../utils/user-platform-api-schemas'
import { Checkbox } from './checkbox'
import { ImportanceBadge } from './importance-badge'
import * as styles from './scope-and-timeline-controls.css.ts'

type ScopeAndTimelineControlsProps = {
  /**
   * scope can be either account or repository full name or all
   * eg. `pixee` or `pixee/codemodder-python` or `all`
   */
  scope: string
  username?: string
  installations: Installation[]
  repositories: RepositoryWithInstallationId[]
  handleNavigate: (value: string) => void
  isChartVisible: boolean
  handleToggleIsChartVisible: () => void
  timeRangeInDays: number
  handleTimeRangeChange: (value: string) => void
  isHighImportanceEnabled: boolean
  isMediumImportanceEnabled: boolean
  isLowImportanceEnabled: boolean
  handleToggleIsHighImportanceEnabled: () => void
  handleToggleIsMediumImportanceEnabled: () => void
  handleToggleIsLowImportanceEnabled: () => void
}

export function ScopeAndTimelineControls({
  scope,
  username,
  installations,
  repositories,
  handleNavigate,
  isChartVisible,
  handleToggleIsChartVisible,
  timeRangeInDays,
  handleTimeRangeChange,
  isHighImportanceEnabled,
  isMediumImportanceEnabled,
  isLowImportanceEnabled,
  handleToggleIsHighImportanceEnabled,
  handleToggleIsMediumImportanceEnabled,
  handleToggleIsLowImportanceEnabled,
}: ScopeAndTimelineControlsProps) {
  const currentRepository = repositories.find(repository => repository.full_name === scope)
  const currentInstallation = currentRepository
    ? installations.find(installation => installation.id === currentRepository.installation_id)
    : installations.find(installation => installation.account.login === scope)
  const environmentData = useEnvironmentData()

  return (
    <div className={classnames(styles.barContainer, styles.bottomBorder[isChartVisible ? 'show' : 'hide'])}>
      <div style={{ height: '106px' }}>
        <p className={styles.userGreeting}>{username ? `Hello @${username}` : '\u00A0'}</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {currentInstallation ? (
            <img className={styles.avatar} src={currentInstallation.account.avatar_url} />
          ) : (
            <MarkGithubIcon className={styles.avatar} />
          )}
          <Select.Root onValueChange={handleNavigate} defaultValue={scope}>
            <Select.Trigger className={styles.selectTrigger} aria-label="issues-scope">
              <Select.Value />
              <Select.Icon>
                <ChevronDownIcon className={styles.chevronDown} />
                <ChevronUpIcon className={styles.chevronUp} />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content
                className={styles.selectContent}
                onCloseAutoFocus={e => {
                  e.preventDefault()
                }}
                position="popper"
                side="bottom"
                avoidCollisions={false}
                alignOffset={-7}
                sideOffset={6}
              >
                <Select.ScrollUpButton className={styles.selectScrollButton}>
                  <ChevronUpIcon />
                </Select.ScrollUpButton>
                <Select.Viewport>
                  <Select.Group>
                    <SelectItem value="all" text="All repositories" />
                  </Select.Group>
                  <Select.Separator className={styles.selectSeparator} />
                  <Select.Group>
                    {installations.map(installation => (
                      <SelectItem
                        key={installation.id}
                        value={installation.account.login}
                        text={installation.account.login}
                      >
                        <img className={styles.installationIcon} src={installation.account.avatar_url} />
                      </SelectItem>
                    ))}
                  </Select.Group>
                  <Select.Separator className={styles.selectSeparator} />
                  <div className={styles.reposGroups}>
                    {installations.map(installation => (
                      <Select.Group key={installation.id}>
                        {repositories
                          .filter(repository => repository.owner.login === installation.account.login)
                          .map(repository => (
                            <SelectItem key={repository.id} value={repository.full_name} text={repository.name}>
                              <span className={styles.accountText}>
                                {repository.owner.login.length > 7
                                  ? repository.owner.login.slice(0, 7) + '...'
                                  : repository.owner.login}
                              </span>
                              <span className={styles.accountRepoSeparator}>/</span>
                            </SelectItem>
                          ))}
                      </Select.Group>
                    ))}
                  </div>
                </Select.Viewport>
                <Select.ScrollDownButton className={styles.selectScrollButton}>
                  <ChevronDownIcon />
                </Select.ScrollDownButton>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
        </div>
        {!currentRepository && currentInstallation && (
          <div style={{ marginLeft: '48px' }}>
            <p className={styles.repositoriesCountLabel}>
              <span className={styles.repositoriesCount}>
                {repositories.length === 0
                  ? '-'
                  : repositories.filter(repository => repository.installation_id === currentInstallation.id).length}
              </span>
              Repositories
            </p>
          </div>
        )}
        {scope === 'all' && (
          <div style={{ marginLeft: '48px' }} className={styles.installationsRepoCountGroup}>
            <p className={styles.repositoriesCountLabel}>
              <span className={styles.repositoriesCount}>
                {installations.length === 0 ? '-' : installations.length}
              </span>{' '}
              Organizations
            </p>
            <p className={styles.repositoriesCountLabel}>
              <span className={styles.repositoriesCount}>{repositories.length === 0 ? '-' : repositories.length}</span>{' '}
              Repositories
            </p>
          </div>
        )}
      </div>
      <div className={styles.rightGroup}>
        <Select.Root defaultValue={String(timeRangeInDays)} onValueChange={handleTimeRangeChange}>
          <Select.Trigger className={styles.selectTriggerTimeline} aria-label="time-range-scope">
            <Select.Value />
            <Select.Icon>
              <ChevronDownIcon className={styles.chevronDown} />
              <ChevronUpIcon className={styles.chevronUp} />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content
              className={styles.selectContentTimeline}
              onCloseAutoFocus={e => {
                e.preventDefault()
              }}
              position="popper"
              side="bottom"
              avoidCollisions={false}
              alignOffset={-7}
              sideOffset={18}
            >
              <Select.ScrollUpButton className={styles.selectScrollButton}>
                <ChevronUpIcon />
              </Select.ScrollUpButton>
              <Select.Viewport>
                <SelectItem value={'7'} text="Last 7 Days" />
                <SelectItem value={'30'} text="Last 30 Days" />
              </Select.Viewport>
              <Select.ScrollDownButton className={styles.selectScrollButton}>
                <ChevronDownIcon />
              </Select.ScrollDownButton>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger className={styles.selectTriggerTimeline} asChild aria-label="importance-scope">
            <div>
              <ImportanceBadge variant="HIGH" style={isHighImportanceEnabled ? {} : { opacity: 0.4 }} />
              <ImportanceBadge variant="MEDIUM" style={isMediumImportanceEnabled ? {} : { opacity: 0.4 }} />
              <ImportanceBadge variant="LOW" style={isLowImportanceEnabled ? {} : { opacity: 0.4 }} />
              <ChevronDownIcon className={styles.chevronDown} />
              <ChevronUpIcon className={styles.chevronUp} />
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className={styles.selectContentImportance}
              onCloseAutoFocus={e => e.preventDefault()}
              sideOffset={18}
            >
              <CheckboxItem
                text="High"
                isChecked={isHighImportanceEnabled}
                handleToggle={handleToggleIsHighImportanceEnabled}
              />
              <CheckboxItem
                text="Medium"
                isChecked={isMediumImportanceEnabled}
                handleToggle={handleToggleIsMediumImportanceEnabled}
              />
              <CheckboxItem
                text="Low"
                isChecked={isLowImportanceEnabled}
                handleToggle={handleToggleIsLowImportanceEnabled}
              />
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
        <button
          className={styles.toggleChartButton}
          onClick={handleToggleIsChartVisible}
          aria-label={
            isChartVisible
              ? `Hide stacked area chart visualizing ${environmentData.githubAppName} PRs over time`
              : `Show chart stacked area chart visualizing ${environmentData.githubAppName} PRs over time`
          }
        >
          {isChartVisible ? (
            <>
              Hide chart <ChevronUpIcon />
            </>
          ) : (
            <>
              Show chart <ChevronDownIcon />
            </>
          )}
        </button>
      </div>
    </div>
  )
}

interface SelectItemProps {
  children?: React.ReactNode
  text: string
  className?: string
  value: string
  disabled?: boolean
}

const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, text, className, ...props }, forwardedRef) => {
    return (
      <Select.Item className={classnames(styles.selectItem, className)} {...props} ref={forwardedRef}>
        {children}
        <Select.ItemText>{text}</Select.ItemText>
        <Select.ItemIndicator className={styles.selectItemIndicator}>
          <CheckIcon className={styles.checkIcon} />
        </Select.ItemIndicator>
      </Select.Item>
    )
  }
)

type CheckboxItemProps = {
  text: string
  isChecked: boolean
  handleToggle: () => void
}

function CheckboxItem({ text, isChecked, handleToggle }: CheckboxItemProps) {
  return (
    <DropdownMenu.CheckboxItem
      className={styles.checkboxItem}
      onSelect={event => event.preventDefault()}
      checked={isChecked}
      onCheckedChange={handleToggle}
    >
      <Checkbox checked={isChecked} Indicator={DropdownMenu.ItemIndicator} />
      <span className={styles.checkboxText}>{text}</span>
    </DropdownMenu.CheckboxItem>
  )
}
