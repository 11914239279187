import { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { match } from 'ts-pattern'
import { FullPageSpinner } from '../components/full-page-spinner'
import { useAuth } from './auth-provider'

/**
 * Renders page optimistically before confirmed is authenticated.
 */
export function WithOptimisticAuth(props: PropsWithChildren) {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  return getPageOptimistically(isAuthenticated, props.children, location.pathname)
}

/**
 * Only renders page if confirmed authenticated.
 */
export function WithRequiredAuth(props: PropsWithChildren) {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  return getPageIfAuthenticated(isAuthenticated, props.children, location.pathname)
}

export const getPageOptimistically = (
  isAuthenticated: boolean | undefined,
  children: React.ReactNode,
  redirectPath?: string
) => {
  return match(isAuthenticated)
    .with(undefined, () => children)
    .with(false, () => <Navigate to={redirectPath ? `/login?redirect_path=${redirectPath}` : '/login'} replace />)
    .with(true, () => children)
    .exhaustive()
}

export const getPageIfAuthenticated = (
  isAuthenticated: boolean | undefined,
  children: React.ReactNode,
  redirectPath?: string
) => {
  return match(isAuthenticated)
    .with(undefined, () => <FullPageSpinner />)
    .with(false, () => <Navigate to={redirectPath ? `/login?redirect_path=${redirectPath}` : '/login'} replace />)
    .with(true, () => children)
    .exhaustive()
}
