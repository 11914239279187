import * as styles from './spinner.css.ts'

export function Spinner() {
  return (
    <span role="status" aria-label="Loading">
      <svg height={24} width={24} viewBox="0 0 16 16" fill="none" className={styles.spinner}>
        <circle
          cx="8"
          cy="8"
          r="7"
          stroke="currentColor"
          strokeOpacity="0.25"
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M15 8a7.002 7.002 0 00-7-7"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </span>
  )
}
