import { useContext } from 'react'
import { ThemeContext } from '../utils/use-theme'
import './footer.css'

export const Footer = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <footer className="footer mt-auto footer-container" id={theme}>
      <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
        <p className="col-md-5 mb-0 small footer-trade-mark-para">
          <span className="small-bold">© 2023 Pixee Inc.</span> All rights reserved.{' '}
        </p>
        <ul className="nav col-md-5 justify-content-end">
          <li className="nav-item">
            <a href="https://www.pixee.ai/terms" target="_blank" className="footer-link small">
              Terms of Service
            </a>
          </li>
          <li className="nav-item" style={{ marginLeft: '32px' }}>
            <a href="https://www.pixee.ai/privacy" target="_blank" className="footer-link small">
              Privacy Policy
            </a>
          </li>
          <li className="nav-item" style={{ marginLeft: '32px' }}>
            <a href="mailto:feedback@pixee.ai" target="_blank" className="footer-link small">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}
