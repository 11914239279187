import * as AlertDialog from '@radix-ui/react-alert-dialog'
import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'
import * as RadioGroup from '@radix-ui/react-radio-group'
import * as Select from '@radix-ui/react-select'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { typography } from '../main.css.ts'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import {
  useGetInstallations,
  useGetOwnerPreferences,
  usePutOwnerPreferenceMutation,
} from '../utils/user-platform-api-hooks'
import { CodemodPreference } from '../utils/user-platform-api-schemas.ts'
import * as styles from './codemod-preference-configuration-dialog.css.ts'
import { DefaultButton } from './default-button'
import { GhostButton } from './ghost-button'
import { ToastVariants } from './toast.css.ts'

export function CodemodPreferenceConfigurationDialog({
  handleCloseConfigurationDialog,
  handleAddToastWithTimeout,
}: {
  handleCloseConfigurationDialog: any
  handleAddToastWithTimeout: (toastProps: { message: JSX.Element; variant: ToastVariants }) => void
}) {
  let { data: installations, isLoading: installationsIsLoading } = useGetInstallations()
  const { data: ownerPreferences, isSuccess: ownerPreferencesIsSuccess } = useGetOwnerPreferences(
    installations.map(installation => installation.account.id)
  )

  const queryClient = useQueryClient()
  const updateOwnerPreferenceMutation = usePutOwnerPreferenceMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['owner-preferences'] })
      handleCloseConfigurationDialog()
      handleAddToastWithTimeout({ message: <>Codemod preference update was successful.</>, variant: 'success' })
    },
    onError: () => {
      handleAddToastWithTimeout({
        message: <>Update failed. An error occurred, please try again.</>,
        variant: 'error',
      })
    },
  })

  const [currentOwnerSelected, setCurrentOwnerSelected] = useState<number | undefined>(installations[0]?.account.id)
  const [selectedCodemodPreference, setSelectedCodemodPreference] = useState<CodemodPreference | undefined>(undefined)
  let currentOwnerPreference = ownerPreferences?.find(({ ownerId }) => ownerId === currentOwnerSelected)

  useEffect(() => {
    if (ownerPreferencesIsSuccess) {
      currentOwnerPreference = ownerPreferences?.find(({ ownerId }) => ownerId === currentOwnerSelected)
      setSelectedCodemodPreference(currentOwnerPreference?.codemodPreference)
    }
  }, [ownerPreferences])

  const [isOwnerSelectionOpen, setIsOwnerSelectionOpen] = useState<boolean>(false)
  const environmentData = useEnvironmentData()

  return (
    <AlertDialog.Root open={true}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className={styles.alertDialogOverlay} />
        <AlertDialog.Content className={styles.alertDialogContent}>
          <AlertDialog.Title className={styles.welcome}>Default preference</AlertDialog.Title>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
            <AlertDialog.Description asChild>
              <div>
                <p className={styles.paragraph} style={{ margin: '2px' }}>
                  Choose the most relevant use case for the repositories in your installation as your default. You can
                  always customize this further or override at the repository level.{' '}
                </p>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <a
                    href="https://docs.pixee.ai/configuring"
                    target="_blank"
                    className={classNames(styles.link, typography.paragraph)}
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </AlertDialog.Description>
            {installations && (
              <div>
                <p className={typography.smallBold} style={{ margin: '8px' }}>
                  Account
                </p>
                <Select.Root
                  defaultValue={String(installations[0]?.account.id)}
                  onValueChange={selectedOwnerId => {
                    setCurrentOwnerSelected(Number(selectedOwnerId))
                    const defaultPreference = ownerPreferences?.find(
                      ({ ownerId }) => ownerId === Number(selectedOwnerId)
                    )?.codemodPreference
                    setSelectedCodemodPreference(defaultPreference)
                  }}
                  open={isOwnerSelectionOpen}
                  onOpenChange={isOpen => setIsOwnerSelectionOpen(isOpen)}
                >
                  <Select.Trigger className={classNames(typography.paragraph, styles.accountBox)}>
                    <Select.Value />
                    {isOwnerSelectionOpen ? (
                      <TriangleUpIcon className={styles.triangleIcon} />
                    ) : (
                      <TriangleDownIcon className={styles.triangleIcon} />
                    )}
                  </Select.Trigger>

                  <Select.Content
                    className={styles.selectContent}
                    onCloseAutoFocus={e => {
                      e.preventDefault()
                    }}
                    position="popper"
                    side="bottom"
                    avoidCollisions={false}
                    sideOffset={6}
                  >
                    {installations.map(installation => (
                      <Select.Item key={installation.account.id} value={String(installation.account.id)} asChild>
                        <div className={styles.selectItem}>
                          <Select.ItemText asChild>
                            <div style={{ display: 'flex' }}>
                              <img className={styles.avatar} src={installation.account.avatar_url} />
                              <p style={{ margin: 0, verticalAlign: 'center' }}>{installation.account.login}</p>
                            </div>
                          </Select.ItemText>
                        </div>
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </div>
            )}
            {ownerPreferences && (
              <form>
                <RadioGroup.Root
                  value={selectedCodemodPreference}
                  onValueChange={value =>
                    // @ts-expect-error
                    setSelectedCodemodPreference(value)
                  }
                  aria-label="Codemod configuration"
                >
                  <div className={styles.tilesContainer}>
                    <RadioGroup.Item value="everything" id="tile1">
                      <div className={styles.tile}>
                        <label htmlFor="tile1" className={styles.tileLabel}>
                          <p className={styles.tileTitle}>
                            Everything
                            {currentOwnerPreference?.codemodPreference === 'everything' && (
                              <span className={typography.extraSmallItalic}>Current</span>
                            )}
                          </p>
                          <p className={styles.tileDescription}>
                            Give me all security and quality recommendations from {environmentData.githubAppName} , as
                            well as fixes for my connected security tools
                          </p>
                        </label>
                      </div>
                    </RadioGroup.Item>
                    <RadioGroup.Item value="security+tools" id="tile2">
                      <div className={styles.tile}>
                        <label htmlFor="tile2" className={styles.tileLabel}>
                          <p className={styles.tileTitle}>
                            Security + Tools
                            {currentOwnerPreference?.codemodPreference === 'security+tools' && (
                              <span className={typography.extraSmallItalic}>Current</span>
                            )}
                          </p>
                          <p className={styles.tileDescription} style={{ width: '219px' }}>
                            I prefer to focus on {environmentData.githubAppName}’s security recommendations and my
                            security tool fixes
                          </p>
                        </label>
                      </div>
                    </RadioGroup.Item>
                    <RadioGroup.Item value="tools-only" id="tile3">
                      <div className={styles.tile}>
                        <label htmlFor="tile3" className={styles.tileLabel}>
                          <p className={styles.tileTitle}>
                            Tools only
                            {currentOwnerPreference?.codemodPreference === 'tools-only' && (
                              <span className={typography.extraSmallItalic}>Current</span>
                            )}
                          </p>
                          <p className={styles.tileDescription} style={{ width: '243px' }}>
                            Just fix the findings from my connected security tools and omit{' '}
                            {environmentData.githubAppName} recommendations for now
                          </p>
                        </label>
                      </div>
                    </RadioGroup.Item>
                    <RadioGroup.Item value="custom" id="tile4">
                      <div className={styles.tile}>
                        <label htmlFor="tile4" className={styles.tileLabel}>
                          <p className={styles.tileTitle}>
                            Custom
                            {currentOwnerPreference?.codemodPreference === 'custom' && (
                              <span className={typography.extraSmallItalic}>Current</span>
                            )}
                          </p>
                          <p className={styles.tileDescription}>I'll create a default from scratch</p>
                        </label>
                      </div>
                    </RadioGroup.Item>
                  </div>
                </RadioGroup.Root>
              </form>
            )}
            {(installationsIsLoading || !currentOwnerPreference) && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span className={styles.spinner} />
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <a href="https://tally.so/r/3qbdr5" target="_blank" className={classNames(styles.link, typography.small)}>
                Give feedback
              </a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', gap: '16px' }}>
                <GhostButton type="secondary" onClick={handleCloseConfigurationDialog}>
                  Cancel
                </GhostButton>
                <AlertDialog.Action asChild>
                  <DefaultButton
                    onClick={() => {
                      if (currentOwnerPreference && selectedCodemodPreference) {
                        updateOwnerPreferenceMutation.mutate({
                          ownerId: currentOwnerPreference.ownerId,
                          codemodPreference: selectedCodemodPreference,
                        })
                      }
                    }}
                    state={
                      updateOwnerPreferenceMutation.isPending
                        ? 'loading'
                        : currentOwnerPreference &&
                            selectedCodemodPreference &&
                            currentOwnerPreference.codemodPreference !== selectedCodemodPreference
                          ? 'default'
                          : 'disabled'
                    }
                  >
                    Update
                  </DefaultButton>
                </AlertDialog.Action>
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}
