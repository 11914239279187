import * as Portal from '@radix-ui/react-portal'
import * as styles from './toast-container.css.ts'

export const ToastContainer = ({ children }) => {
  return (
    <Portal.Root asChild>
      <div className={styles.toastContainer}>{children}</div>
    </Portal.Root>
  )
}
