import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Cross1Icon } from '@radix-ui/react-icons'
import classNames from 'classnames'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import { Checkbox } from './checkbox'
import * as styles from './dropdown-filter-select.css.ts'
import { GhostButton } from './ghost-button'
import { OutlineButton } from './outline-button'

type DropdownFilterSelectProps = {
  columnFilters: { id: string; value: string[] }[]
  setColumnFilters?: Function
  toggleAnalysisColumnFilter?: Function
  toggleStatusColumnFilter?: Function
}

export function DropdownFilterSelect({
  columnFilters,
  setColumnFilters = () => {},
  toggleAnalysisColumnFilter = () => () => {},
  toggleStatusColumnFilter = () => () => {},
}: DropdownFilterSelectProps) {
  const environmentData = useEnvironmentData()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <OutlineButton
          state={columnFilters.flatMap(filter => filter.value).length > 0 ? 'active' : 'inactive'}
          size="small"
        >
          Filter ({columnFilters.flatMap(filter => filter.value).length})
        </OutlineButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={classNames(styles.dropdownContainer, styles.disableOutlineHover)}
          onCloseAutoFocus={e => e.preventDefault()}
        >
          <DropdownMenu.Group className={styles.group}>
            <DropdownMenu.Label
              className={styles.label}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '24px' }}
            >
              <div>
                <img src={environmentData.logoIconHref} style={{ height: '24px', marginRight: '8px' }} />
                Status
              </div>
              {columnFilters.length > 0 && (
                <DropdownMenu.Item
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                      e.preventDefault()
                      setColumnFilters([])
                    }
                  }}
                  onSelect={e => e.preventDefault()}
                  className={classNames(styles.item, styles.disableOutlineHover)}
                  style={{ outline: 'none' }}
                >
                  <GhostButton size="small" type="secondary" onClick={() => setColumnFilters([])}>
                    <Cross1Icon width={13} height={13} className={styles.clearButtonIcon} />
                    Clear
                  </GhostButton>
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Label>
            <CheckboxItem
              text={'Suggestions'}
              isChecked={() =>
                columnFilters.some(({ id, value }) => id === 'status' && value.some(value => value === 'suggestions'))
              }
              toggleColumnFilter={toggleAnalysisColumnFilter('suggestions')}
            />
            <CheckboxItem
              text={'Pending'}
              isChecked={() =>
                columnFilters.some(({ id, value }) => id === 'status' && value.some(value => value === 'pending'))
              }
              toggleColumnFilter={toggleAnalysisColumnFilter('pending')}
            />
            <CheckboxItem
              text={'No suggestions'}
              isChecked={() =>
                columnFilters.some(
                  ({ id, value }) => id === 'status' && value.some(value => value === 'no-suggestions')
                )
              }
              toggleColumnFilter={toggleAnalysisColumnFilter('no-suggestions')}
            />
          </DropdownMenu.Group>
          <DropdownMenu.Group className={styles.group}>
            <DropdownMenu.Label className={styles.label}>Active</DropdownMenu.Label>
            <CheckboxItem
              text={'Enabled'}
              isChecked={() =>
                columnFilters.some(({ id, value }) => id === 'active' && value.some(value => value === 'true'))
              }
              toggleColumnFilter={toggleStatusColumnFilter('true')}
            />
            <CheckboxItem
              text={'Disabled'}
              isChecked={() =>
                columnFilters.some(({ id, value }) => id === 'active' && value.some(value => value === 'false'))
              }
              toggleColumnFilter={toggleStatusColumnFilter('false')}
            />
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

type CheckboxItemProps = {
  text: string
  isChecked: () => boolean
  toggleColumnFilter: Function
}

function CheckboxItem({ text, isChecked, toggleColumnFilter }: CheckboxItemProps) {
  return (
    <DropdownMenu.CheckboxItem
      className={styles.checkboxItem}
      onSelect={event => event.preventDefault()}
      checked={isChecked()}
      onCheckedChange={() => {
        toggleColumnFilter()
      }}
    >
      <Checkbox checked={isChecked()} Indicator={DropdownMenu.ItemIndicator} />
      <span className={styles.checkboxText}>{text}</span>
    </DropdownMenu.CheckboxItem>
  )
}
