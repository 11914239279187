import { InfoCircledIcon } from '@radix-ui/react-icons'
import * as Tooltip from '@radix-ui/react-tooltip'
import { match } from 'ts-pattern'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import * as styles from './pixee-status-badge.css.ts'
import TooltipWithClickToOpen from './tooltip-with-click-to-open'

type PixeeStatusBadgeProps = {
  variant: 'suggestions' | 'pending' | 'no-suggestions' | 'waitlisted' | 'inactive'
  suggestionsCount?: number
}

export function PixeeStatusBadge({ variant, suggestionsCount }: PixeeStatusBadgeProps) {
  const environmentData = useEnvironmentData()

  const text = match(variant)
    .with('suggestions', () => (
      <span>
        {suggestionsCount} {suggestionsCount! > 1 ? 'fixes' : 'fix'}
      </span>
    ))
    .with('pending', () => (
      <span title="The most recent code on the default branch has not been analyzed by pixeebot yet">Pending</span>
    ))
    .with('no-suggestions', () => <span>No fixes</span>)
    .with('waitlisted', () => (
      <span className={styles.waitlistedBadge}>
        Waitlisted
        <TooltipWithClickToOpen>
          <Tooltip.Trigger>
            <InfoCircledIcon className={styles.infoIcon} />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.tooltip} sideOffset={50} side="right">
              <Tooltip.Arrow className={styles.tooltipArrow} />
              This repository's composition does not meet the minimum percentage of supported languages (Java and
              Python).
              <br />
              <br />
              You can leave this installation enabled and when its language is supported,{' '}
              {environmentData.githubAppName} will automatically start analyzing it.
            </Tooltip.Content>
          </Tooltip.Portal>
        </TooltipWithClickToOpen>
      </span>
    ))
    .with('inactive', () => <span>Inactive</span>)
    .exhaustive()

  return <p className={styles.badge[variant]}>{text}</p>
}
