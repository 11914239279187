import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import * as Tooltip from '@radix-ui/react-tooltip'
import { DateTime } from 'luxon'
import { match } from 'ts-pattern'
import {
  AnalysisStatusLog,
  PullRequest,
  RepositoryActivation,
  RepositoryWithInstallationId,
} from '../utils/user-platform-api-schemas'
import { PixeeStatusBadge } from './pixee-status-badge'
import * as styles from './repository-status.css.ts'
import TooltipWithClickToOpen from './tooltip-with-click-to-open'

type RepoStatusProps = {
  repository: RepositoryWithInstallationId
  repositoryPixeebotOpenPRs: PullRequest[]
  statusMetadata: ReturnType<typeof calculateRepositoryStatus>
}

export function RepoStatus({ repository, repositoryPixeebotOpenPRs, statusMetadata }: RepoStatusProps) {
  const badge = match(statusMetadata.status)
    .with('suggestions', () => (
      <a
        href={`${repository.html_url}/pulls?q=${repositoryPixeebotOpenPRs.map(pr => `is:pr is:open ${pr.number}`).join('+')}`}
        target="_blank"
        className={'link-light text-decoration-none'}
        title={`Click to view pixeebot's suggestions for hardening the ${repository.default_branch} branch`}
        style={{ width: 'fit-content', display: 'inline-block' }}
      >
        <PixeeStatusBadge variant="suggestions" suggestionsCount={repositoryPixeebotOpenPRs.length} />
      </a>
    ))
    .with('no-suggestions', () => <PixeeStatusBadge variant="no-suggestions" />)
    .with('pending', () => <PixeeStatusBadge variant="pending" />)
    .with('waitlisted', () => <PixeeStatusBadge variant="waitlisted" />)
    .with('inactive', () => <PixeeStatusBadge variant="inactive" />)
    .with('loading', () => (
      <div
        className="spinner-border extra-small-bold"
        role="status"
        aria-label="Loading"
        style={{ height: 12, width: 12 }}
      >
        <span className="sr-only" />
      </div>
    ))
    .exhaustive()

  return (
    <div className={styles.statusContainer}>
      {badge}
      <AnalysisStatusTooltip {...statusMetadata} />
    </div>
  )
}

export function AnalysisStatusTooltip({
  latestAnalysisStatus,
  latestAnalysisTimestamp,
}: {
  latestAnalysisStatus: AnalysisStatus
  latestAnalysisTimestamp?: string
}) {
  if (latestAnalysisStatus === 'EMPTY' || latestAnalysisStatus === 'LOADING' || !latestAnalysisTimestamp) return <></>

  const text = match(latestAnalysisStatus)
    .with('COMPLETED_NO_RESULTS', () => 'Completed')
    .with('COMPLETED_RESULTS', () => 'Completed')
    .with('IN_PROGRESS', () => 'In progress')
    .with('QUEUED', () => 'Queued')
    .with('FAILED', () => 'Failed')
    .with('SKIPPED', () => 'Skipped')
    .exhaustive()

  const icon = match(latestAnalysisStatus)
    .with('COMPLETED_NO_RESULTS', () => <CheckIcon className={styles.tooltipIcon} />)
    .with('COMPLETED_RESULTS', () => <CheckIcon className={styles.tooltipIcon} />)
    .with('IN_PROGRESS', () => (
      <div className={styles.tooltipIconInProgressContainer}>
        <span className={styles.tooltipIconInProgressRing} />
        <span className={styles.tooltipIconInProgress} />
      </div>
    ))
    .with('QUEUED', () => <span className={styles.tooltipIconQueued} />)
    .with('FAILED', () => <Cross2Icon className={styles.tooltipIcon} />)
    .with('SKIPPED', () => <Cross2Icon className={styles.tooltipIcon} />)
    .exhaustive()

  return (
    <TooltipWithClickToOpen>
      <Tooltip.Trigger>{icon}</Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className={styles.tooltip} sideOffset={4} side="right">
          <Tooltip.Arrow className={styles.tooltipArrow} />
          {text}
          <br />
          {`${DateTime.fromISO(latestAnalysisTimestamp).toRelative()} (${DateTime.fromISO(latestAnalysisTimestamp).toFormat('M/d/yy')})`}
        </Tooltip.Content>
      </Tooltip.Portal>
    </TooltipWithClickToOpen>
  )
}

export type RepositoryStatus = 'suggestions' | 'pending' | 'no-suggestions' | 'loading' | 'waitlisted' | 'inactive'

const statusOrder: RepositoryStatus[] = [
  'suggestions',
  'pending',
  'no-suggestions',
  'loading',
  'waitlisted',
  'inactive',
]

export function sortStatuses(a: RepositoryStatus, b: RepositoryStatus): number {
  return statusOrder.indexOf(a) - statusOrder.indexOf(b)
}

export type AnalysisStatus = AnalysisStatusLog['status'] | 'EMPTY' | 'LOADING'

type CalculateRepositoryStatus = {
  repositoryPixeebotOpenPRs: PullRequest[]
  analyses: AnalysisStatusLog[]
  repositoryActivation?: RepositoryActivation
}

export function calculateRepositoryStatus({
  repositoryPixeebotOpenPRs,
  analyses,
  repositoryActivation,
}: CalculateRepositoryStatus): {
  status: RepositoryStatus
  latestAnalysisStatus: AnalysisStatus
  latestAnalysisTimestamp?: string
} {
  const latestAnalysis: AnalysisStatusLog | undefined = analyses.sort(
    (analysisA, analysisB) => new Date(analysisB.created_at).getTime() - new Date(analysisA.created_at).getTime()
  )[0]

  let latestAnalysisStatus: AnalysisStatus = latestAnalysis?.status ?? 'LOADING'

  const status = calculateStatus(repositoryPixeebotOpenPRs, latestAnalysisStatus, repositoryActivation)

  latestAnalysisStatus =
    ['inactive', 'waitlisted'].includes(status) || repositoryActivation === undefined ? 'EMPTY' : latestAnalysisStatus

  return {
    status,
    latestAnalysisStatus,
    latestAnalysisTimestamp: latestAnalysis?.created_at,
  }
}

function calculateStatus(
  repositoryPixeebotOpenPRs: PullRequest[],
  latestAnalysisStatus: AnalysisStatus,
  repositoryActivation?: RepositoryActivation
): RepositoryStatus {
  if (repositoryPixeebotOpenPRs.length >= 1) return 'suggestions'

  if (repositoryActivation) {
    const { user_activated, pixee_approved } = repositoryActivation
    if (user_activated === false) return 'inactive'
    if (pixee_approved === false) return 'waitlisted'
  }

  if (latestAnalysisStatus === 'IN_PROGRESS' || latestAnalysisStatus === 'QUEUED') return 'pending'
  // if 'COMPLETED_RESULTS' or 'FAILED' but no remaining prs opened, then the show no-suggestions badge
  if (['COMPLETED_NO_RESULTS', 'COMPLETED_RESULTS', 'FAILED', 'SKIPPED'].includes(latestAnalysisStatus))
    return 'no-suggestions'

  return 'loading'
}
