import { InfoCircledIcon } from '@radix-ui/react-icons'
import * as Tooltip from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import * as styles from './calculated-metrics-bar.css.ts'
import TooltipWithClickToOpen from './tooltip-with-click-to-open'

type CalculatedMetricsBarProps = {
  avgMergeRate: number
  hoursSaved: number
  mergedFixes: number
  prsHardened: number
  codemodsCount: number
  analysesCompleted: number
  analysesInProgressOrQueued: number
  analysesIsPending: boolean
  pullRequestsIsPending: boolean
  analysesIsEmpty: boolean
}

export function CalculatedMetricsBar({
  avgMergeRate,
  hoursSaved,
  mergedFixes,
  prsHardened,
  codemodsCount,
  analysesCompleted,
  analysesInProgressOrQueued,
  pullRequestsIsPending,
  analysesIsPending,
  analysesIsEmpty,
}: CalculatedMetricsBarProps) {
  const environmentData = useEnvironmentData()

  return (
    <section className={styles.section}>
      <figure className={styles.figure}>
        <figcaption className={styles.figcaption}>
          Avg. merge rate
          <TooltipWithClickToOpen>
            <Tooltip.Trigger>
              <InfoCircledIcon className={styles.infoIcon} />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className={styles.tooltip} sideOffset={3}>
                The number of {environmentData.githubAppName} pull requests that have been merged, divided by the total
                number of {environmentData.githubAppName} pull requests
              </Tooltip.Content>
            </Tooltip.Portal>
          </TooltipWithClickToOpen>
        </figcaption>
        <p className={styles.number}>{pullRequestsIsPending ? '-' : formatAverageMergeRate(avgMergeRate)}%</p>
      </figure>
      <figure className={styles.figure}>
        <figcaption className={styles.figcaption}>Merged fixes</figcaption>
        <p className={styles.number}>{pullRequestsIsPending ? '-' : mergedFixes}</p>
      </figure>
      <figure className={styles.figure}>
        <figcaption className={styles.figcaption}>
          Hrs saved
          <TooltipWithClickToOpen>
            <Tooltip.Trigger>
              <InfoCircledIcon className={styles.infoIcon} />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className={styles.tooltip} sideOffset={3}>
                More advanced security tools report between 20 minutes
                <a href="https://linghuiluo.github.io/FSE21.pdf" target="_blank" className={styles.source}>
                  1
                </a>{' '}
                and 2 hours
                <a
                  href="https://www.contrastsecurity.com/hubfs/DocumentsPDF/The-State-of-DevSecOps_Report_Final.pdf"
                  target="_blank"
                  className={styles.source}
                >
                  2
                </a>{' '}
                to remediate a single vulnerability, so we estimate an average of 1 hour per codemod merged.
              </Tooltip.Content>
            </Tooltip.Portal>
          </TooltipWithClickToOpen>
        </figcaption>
        <p className={styles.number}>{pullRequestsIsPending ? '-' : hoursSaved}</p>
      </figure>
      <span className={styles.verticalLine} />
      <figure className={styles.figure}>
        <figcaption className={styles.figcaption}>PRs hardened</figcaption>
        <p className={styles.number}>{analysesIsPending ? <span className={styles.spinner}></span> : prsHardened}</p>
      </figure>
      <figure className={styles.figure}>
        <figcaption className={styles.figcaption}>Codemods hit</figcaption>
        <p className={styles.number}>{pullRequestsIsPending ? '-' : codemodsCount}</p>
      </figure>
      <span className={styles.verticalLine} />
      <figure className={styles.figure}>
        <figcaption className={styles.figcaption}>Analyses run</figcaption>
        <p className={classNames(styles.number, analysesInProgressOrQueued > 0 && styles.inProgressNumber)}>
          {analysesIsPending || analysesIsEmpty ? (
            <span className={styles.spinner} role="status" aria-label="Loading" />
          ) : analysesInProgressOrQueued > 0 ? (
            `${analysesInProgressOrQueued} in progress`
          ) : (
            analysesCompleted
          )}
        </p>
      </figure>
      <figure className={styles.figure}>
        <figcaption className={styles.figcaption}>Next scheduled</figcaption>
        <p className={styles.nextScheduledDate}>{analysesIsEmpty ? 'Queued' : getNextTuesday().toFormat('M/dd/yy')}</p>
      </figure>
    </section>
  )
}

const getNextTuesday = () => {
  const now = DateTime.now()
  const currentWeekday = now.weekday
  const daysToAdd = currentWeekday === 2 ? 7 : (9 - currentWeekday) % 7 || 7
  return now.plus({ days: daysToAdd })
}

export const formatAverageMergeRate = (avgMergeRate: number) => {
  if (Number.isNaN(avgMergeRate)) return '0'
  const formattedRate = Math.floor(avgMergeRate).toString()
  return formattedRate.length > 2 ? formattedRate.slice(0, 2) : formattedRate
}
