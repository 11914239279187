const DotLine = ({ theme }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1512 568" fill="none">
      <path
        d="M1512 379.629C1444.43 436.02 1275.49 514.826 964.742 263.85C836.837 160.547 737.624 1 441.425 1C204.466 1 170.492 167.675 169 203C165.508 285.644 194.279 381.643 287.196 429.978C380.112 478.314 460.358 440.048 485.699 409.839C511.039 379.629 529.972 303.147 485.699 263.85C441.425 224.552 363 231.85 305 279C247 326.15 204.213 419.742 157 517C109.787 614.258 -36.9999 555 2.99989 449"
        stroke={theme == 'dark' ? '#656074' : '#E6E4EA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 8"
      />
    </svg>
  )
}
export default DotLine
