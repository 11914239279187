import { SearchIcon } from '@primer/octicons-react'
import { Cross1Icon } from '@radix-ui/react-icons'
import { useEffect, useState } from 'react'
import * as styles from './text-input.css.ts'

type TextInputProps = {
  value?: string
  onChange?: (value: string | number) => void
  size?: 'default' | 'small'
}

export function TextInput({ value, onChange, size = 'default' }: TextInputProps) {
  return <DebouncedInput value={value} onChange={onChange} size={size} />
}

type DebouncedInputProps = {
  value?: string
  onChange?: (value: string) => void
  debounce?: number
  size?: 'default' | 'small'
}

function DebouncedInput({
  value: initialValue = '',
  onChange = () => {},
  debounce = 200,
  size,
  ...props
}: DebouncedInputProps) {
  const [value, setValue] = useState<string>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  const handleKeyDown = e => {
    // Check if Enter (13) or Space (32) was pressed
    if (e.keyCode === 13 || e.keyCode === 32) {
      setValue('')
      e.preventDefault()
    }
  }

  return (
    <div className={styles.searchContainer({ size })}>
      <SearchIcon className={styles.icon} />
      <input
        className={styles.searchInput}
        {...props}
        value={value}
        placeholder="Search"
        onChange={e => setValue(e.target.value)}
      />
      {value.length > 0 && (
        <button onClick={() => setValue('')} onKeyDown={handleKeyDown} aria-label="Clear Search">
          <Cross1Icon className={styles.icon} />
        </button>
      )}
    </div>
  )
}
