import { useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { FullPageSpinner } from '../components/full-page-spinner'
import { InstallationsTable } from '../components/installations-table'
import { NoInstallationsComponent } from '../components/no-installations-component'
import { ThemeContext } from '../utils/use-theme'
import {
  useGetInstallations,
  useGetRepositories,
  useGetRepositoriesActivations,
  useGetUser,
  usePutRepositoriesActivationsMutation,
} from '../utils/user-platform-api-hooks'
import './installations-page.css.ts'
import * as styles from './installations-page.css.ts'
import { useOutletData } from './root'

export function InstallationsPage() {
  const { handleAddToastWithTimeout } = useOutletData()
  const { theme } = useContext(ThemeContext)

  const { data: user } = useGetUser()
  const { data: installations } = useGetInstallations()
  const { data: repositories, isPending: repositoriesIsPending } = useGetRepositories(
    installations ? installations.map(installation => installation.id) : []
  )

  const { data: repositoriesActivation } = useGetRepositoriesActivations(
    installations.map(installation => installation.id)
  )

  const queryClient = useQueryClient()
  const updateRepositoriesActivationMutation = usePutRepositoriesActivationsMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['repositories-activations'] }),
  })

  const handleToggleRepositoriesActivation = async (repositoryIds: number[], toggleState?: boolean) => {
    const repositoriesActivationToToggle = repositoriesActivation.filter(({ repository_id }) =>
      repositoryIds.includes(repository_id)
    )
    if (repositoriesActivationToToggle.length === 0) return

    try {
      await updateRepositoriesActivationMutation.mutateAsync({
        installationIds: installations.map(installation => installation.id),
        repositoryActivations: repositoriesActivationToToggle.map(
          ({ repository_id, user_activated, pixee_approved }) => ({
            repository_id,
            user_activated: toggleState ?? !user_activated,
            pixee_approved,
          })
        ),
      })
      handleAddToastWithTimeout({
        message: (
          <>
            {`${repositoriesActivationToToggle.length > 1 ? `${repositoriesActivationToToggle.length} repositories were` : 'The repository was'} successfully updated`}
          </>
        ),
        variant: 'success',
      })
    } catch (error: any) {
      handleAddToastWithTimeout({
        message: <>There was an error updating repositories</>,
        variant: 'error',
      })
    }
  }

  if (!user) return <></>
  if (!repositoriesIsPending && repositories && repositories.length === 0) return <NoInstallationsComponent />

  return (
    <>
      {updateRepositoriesActivationMutation.isPending && <FullPageSpinner />}
      <main className={styles.mainContainer} id={`${theme}`}>
        <InstallationsTable
          repositories={repositories || []}
          repositoriesActivation={repositoriesActivation}
          handleToggleRepositoriesActivation={handleToggleRepositoriesActivation}
        />
      </main>
    </>
  )
}
