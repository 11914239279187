import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom'
import './context-navigator.css'
import BackArrow from './svg/back-arrow'
import BackArrowBlack from './svg/back-arrow-black'

export const AccountsNavigator = ({ installations, currentAccount, theme }) => {
  if (!installations || installations.length <= 1) return <></>

  return (
    <Dropdown className="drop-down">
      <Dropdown.Toggle variant="tertiary" className="h-6 px-0 selected-account-text">
        {currentAccount.length > 15 ? currentAccount.slice(0, 15 - 3) + '...' : currentAccount}
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="end"
        className="drop-down-menu"
        // @ts-ignore
        style={{ '--bs-dropdown-link-active-bg': '#38393a' }}
      >
        <Dropdown.Item key="home" as={Link} to="/installations" className="navigation-item">
          <div className="navigation-item-container">
            <div>
              {theme === 'dark' ? <BackArrow /> : <BackArrowBlack />}
              <span className="paragraph navigation-text">Installations </span>
            </div>
          </div>
        </Dropdown.Item>
        {installations.map(installation => (
          <Dropdown.Item
            key={installation.id}
            className="context-nav-item"
            as={Link}
            to={`/context/${installation.account.login}`}
          >
            <img src={installation.account.avatar_url} width="20px" />{' '}
            {installation.account.login.length > 15
              ? installation.account.login.slice(0, 15 - 3) + '...'
              : installation.account.login}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
