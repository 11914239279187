import styles from './table-body-skeleton.module.css'

type TableBodySkeletonProps = {
  columnCount: number
  rowCount: number
  rowHeightInPixels: number
}

export function TableBodySkeleton({ columnCount, rowCount, rowHeightInPixels }: TableBodySkeletonProps) {
  return (
    <tbody>
      {Array.from({ length: rowCount }, (_, rowIndex) => (
        <tr key={rowIndex} className={styles.skeletonRow}>
          {Array.from({ length: columnCount }, (_, colIndex) => (
            <td key={colIndex} style={{ height: `${rowHeightInPixels}px` }}></td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
