import { z } from 'zod'

z.setErrorMap((issue, ctx) => {
  console.error(
    // @ts-expect-error
    `Zod Error "${ctx.defaultError}": ${issue.code} at path '${issue.path.join('.')}'. Expected ${issue.expected}, received ${issue.received}. Data: ${JSON.stringify(ctx.data) || ctx.data}`
  )
  return { message: ctx.defaultError }
})

export const User = z.object({
  login: z.string(),
  name: z.string().nullable(),
  avatar_url: z.string(),
  email: z.string(),
})
export type User = z.infer<typeof User>
export const Account = z.object({
  id: z.number(),
  login: z.string(),
  avatar_url: z.string(),
  type: z.enum(['User', 'Organization']),
})
export type Account = z.infer<typeof Account>
export const Installation = z.object({
  id: z.number(),
  account: Account,
})
export const Installations = z.array(Installation)
export type Installation = z.infer<typeof Installation>
export type Installations = z.infer<typeof Installations>
export const Repository = z.object({
  id: z.number(),
  name: z.string(),
  full_name: z.string(),
  owner: Account,
  default_branch: z.string(),
  html_url: z.string(),
  url: z.string(),
})
export type Repository = z.infer<typeof Repository>
export const RepositoryWithInstallationId = Repository.extend({
  installation_id: Installation.shape.id,
})
export type RepositoryWithInstallationId = z.infer<typeof RepositoryWithInstallationId>
export const RepositoriesWithInstallationId = z.array(RepositoryWithInstallationId)
export type RepositoriesWithInstallationId = z.infer<typeof RepositoriesWithInstallationId>
export const PullRequest = z.object({
  id: z.number(),
  number: z.number(),
  title: z.string(),
  created_at: z.string(),
  closed_at: z.string().nullable(),
  pull_request: z.object({
    merged_at: z.string().nullable(),
  }),
  repository_url: z.string().url(),
  html_url: z.string().url(),
  state: z.string(),
})
export type PullRequest = z.infer<typeof PullRequest>
export type PullRequestWithImportance = PullRequest & { importance: 'HIGH' | 'MEDIUM' | 'LOW' | 'UNKNOWN' }
export const PullRequests = z.array(PullRequest)
export type PullRequests = z.infer<typeof PullRequests>
export const CodemodHitStatus = z.object({
  codemod_id: z.string(),
  name: z.string(),
  importance: z.enum(['LOW', 'MEDIUM', 'HIGH', 'UNKNOWN']),
  requires_scanner: z.boolean(),
  scanner: z.enum(['CODEQL', 'CONTRAST', 'DEFECT_DOJO', 'SEMGREP', 'SONAR', 'SNYK', 'APPSCAN', 'PIXEE']),
  result: z.enum(['FOUND', 'NOT_FOUND', 'FAILURE']),
  available: z.boolean(),
})
export type CodemodHitStatus = z.infer<typeof CodemodHitStatus>
export const CodemodHitStatusesByRepositoryId = z.object({
  repository_id: z.number(),
  codemod_hit_statuses: z.array(CodemodHitStatus),
})
export type CodemodHitStatusesByRepositoryId = z.infer<typeof CodemodHitStatusesByRepositoryId>
export const CodemodHitStatusesByRepositoriesId = z.array(CodemodHitStatusesByRepositoryId)
export const CodemodHitStatusesByRepositoriesIdLenient = z.array(
  z.object({
    repository_id: z.number(),
    codemod_hit_statuses: z.array(CodemodHitStatus.extend({ scanner: z.string() })),
  })
)
export type CodemodHitStatusesByRepositoriesIdLenient = z.infer<typeof CodemodHitStatusesByRepositoriesIdLenient>
export type CodemodHitStatusesByRepositoriesId = z.infer<typeof CodemodHitStatusesByRepositoriesId>
export const AnalysisStatusLog = z.object({
  analysis_id: z.string(),
  created_at: z.string(),
  branch: z.string().nullable(),
  sha: z.string().nullable(),
  type: z.enum([
    'INITIAL_IMPROVEMENT',
    'SCHEDULED_IMPROVEMENT',
    'USER_INVOKED_IMPROVEMENT',
    'PR_HARDENING',
    'THIRD_PARTY_TOOL_WEBHOOK',
  ]),
  status: z.enum(['QUEUED', 'IN_PROGRESS', 'SKIPPED', 'COMPLETED_RESULTS', 'COMPLETED_NO_RESULTS', 'FAILED']),
})
export type AnalysisStatusLog = z.infer<typeof AnalysisStatusLog>
export const AnalysisStatusLogsByRepositoryId = z.object({
  repository_id: z.number(),
  analysis_status_logs: z.array(AnalysisStatusLog),
})
export type AnalysisStatusLogsByRepositoryId = z.infer<typeof AnalysisStatusLogsByRepositoryId>
export const AnalysisStatusLogsByRepositoriesId = z.array(AnalysisStatusLogsByRepositoryId)
export type AnalysisStatusLogsByRepositoriesId = z.infer<typeof AnalysisStatusLogsByRepositoriesId>
export const OwnerTier = z.enum(['DENY', 'DEFAULT'])
export const CodemodCatalog = z.enum(['LESS', 'MODERATE', 'MORE', 'CUSTOM', 'DEFAULT'])
export type CodemodCatalog = z.infer<typeof CodemodCatalog>
export const OwnerPreference = z.object({
  owner_id: z.number(),
  tier: OwnerTier,
  codemod_catalog: CodemodCatalog,
})
export type OwnerPreference = z.infer<typeof OwnerPreference>
export const OwnerPreferences = z.array(OwnerPreference)
export type OwnerPreferences = z.infer<typeof OwnerPreferences>
export const CodemodPreference = z.enum(['everything', 'security+tools', 'tools-only', 'custom'])
export type CodemodPreference = z.infer<typeof CodemodPreference>
export const InstallationConfiguration = z.object({
  ownerId: z.number(),
  codemodPreference: CodemodPreference,
})
export type InstallationConfiguration = z.infer<typeof InstallationConfiguration>
export const RepositoryActivation = z.object({
  repository_id: z.number(),
  user_activated: z.boolean(),
  pixee_approved: z.boolean(),
})
export type RepositoryActivation = z.infer<typeof RepositoryActivation>
export const RepositoryActivations = z.array(RepositoryActivation)
export type RepositoryActivations = z.infer<typeof RepositoryActivations>
export const Codemod = z.object({
  id: z.string(),
  name: z.string(),
  importance: CodemodHitStatus.shape.importance,
})
export type Codemod = z.infer<typeof Codemod>
export const Codemods = z.record(z.string(), Codemod)
export type Codemods = z.infer<typeof Codemods>
export const CodemodWithDescription = z.object({
  codemod_id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
})
export type CodemodWithDescription = z.infer<typeof CodemodWithDescription>
export const Changeset = z.object({
  codemodId: z.string(),
  diff: z.string(),
  path: z.string(),
})
export type Changeset = z.infer<typeof Changeset>
export const Changesets = z.array(Changeset)
export type Changesets = z.infer<typeof Changesets>
export const Finding = z.object({
  title: z.string(),
  rule: z.string(),
  severity: z.enum(['LOW', 'MEDIUM', 'HIGH', 'CRITICAL']).optional(),
  suggested_status: z.enum(['wont_fix', 'false_positive', 'true_positive', 'suspicious']).nullable(),
  severity_action: z
    .object({
      old_severity: z.enum(['LOW', 'MEDIUM', 'HIGH', 'CRITICAL']),
      new_severity: z.enum(['LOW', 'MEDIUM', 'HIGH', 'CRITICAL']),
    })
    .nullable(),
  analysis: z.string().nullable(),
  fix_id: z.string().nullable(),
})
export type Finding = z.infer<typeof Finding>
export const Findings = z.array(Finding)
export type Findings = z.infer<typeof Findings>
export const Tool = z
  .enum([
    'CODEQL',
    'SONAR',
    'SONAR_ISSUES',
    'SONAR_HOTSPOTS',
    'SEMGREP',
    'APPSCAN',
    'DEFECTDOJO',
    'CONTRAST',
    'SNYK',
    'CHECKMARX',
  ])
  .or(z.string())
export type Tool = z.infer<typeof Tool>
export const SupportedExternalAnalysisFormat = z
  .enum(['SARIF', 'SONAR_ISSUES', 'SONAR_HOTSPOTS', 'DEFECTDOJO', 'CONTRAST'])
  .or(z.string())
export type SupportedExternalAnalysisFormat = z.infer<typeof SupportedExternalAnalysisFormat>
export const ToolFormat = z.object({
  tool: Tool,
  format: SupportedExternalAnalysisFormat,
})
export type ToolFormat = z.infer<typeof ToolFormat>
export const ScanAnalysis = z.object({
  github_repository_name: z.string(),
  branch: z.string(),
  sha: z.string(),
  scan_id: z.string(),
  analysis_id: z.string(),
  tool_format: ToolFormat,
  uploaded_at: z.string(),
  html_url: z.string().optional(),
})
export type ScanAnalysis = z.infer<typeof ScanAnalysis>
