import { createId } from '@paralleldrive/cuid2'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { useLocalStorage } from '@uidotdev/usehooks'
import { useState } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import { Footer } from '../components/footer.js'
import { NavigationBar } from '../components/navigation-bar'
import { ToastVariants } from '../components/toast.css.ts'
import { AuthProvider } from '../utils/auth-provider.tsx'
import { ThemeContext, useTheme } from '../utils/use-theme'
import * as styles from './root.css.ts'

export function Root() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60_000, // 1 minute
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
          },
        },
        queryCache: new QueryCache(),
        mutationCache: new MutationCache(),
      })
  )

  const [persister] = useState(() =>
    createSyncStoragePersister({
      storage: window.localStorage,
    })
  )

  const { theme, handleToggleTheme } = useTheme()
  const [toasts, setToasts] = useState<{ id: string; message: JSX.Element; variant: ToastVariants }[]>([])
  const handleAddToastWithTimeout: HandleAddToastWithTimeout = toastProps => {
    const id = createId()
    setToasts(toasts => [...toasts, { ...toastProps, id }])
    setTimeout(() => {
      setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))
    }, 5000)
  }
  const [isCheckInstallationsBreadcrumb, setIsCheckInstallationsBreadcrumb] = useLocalStorage<boolean>(
    'ui-state/isCheckInstallationsBreadcrumb',
    false
  )

  return (
    <ThemeContext.Provider
      value={{
        theme,
        handleToggleTheme,
      }}
    >
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <AuthProvider>
          <NavigationBar
            isCheckInstallationsBreadcrumb={isCheckInstallationsBreadcrumb}
            setIsCheckInstallationsBreadcrumb={setIsCheckInstallationsBreadcrumb}
            toasts={toasts}
            handleAddToastWithTimeout={handleAddToastWithTimeout}
          />
          <div className={styles.siteContainer}>
            <main className={styles.main}>
              <Outlet
                context={
                  {
                    setIsCheckInstallationsBreadcrumb,
                    handleAddToastWithTimeout,
                  } satisfies ContextType
                }
              />
            </main>
            <Footer />
          </div>
        </AuthProvider>
      </PersistQueryClientProvider>
    </ThemeContext.Provider>
  )
}

export type HandleAddToastWithTimeout = (toastProps: { message: JSX.Element; variant: ToastVariants }) => void

type ContextType = {
  setIsCheckInstallationsBreadcrumb: React.Dispatch<React.SetStateAction<boolean>>
  handleAddToastWithTimeout: HandleAddToastWithTimeout
}

export function useOutletData() {
  return useOutletContext<ContextType>()
}
