import { createContext, useContext, useEffect, useState } from 'react'
import { getPingIsAuthenticated } from './user-platform-api-hooks'

interface AuthContextType {
  isAuthenticated: boolean | undefined
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [apiIsAuthenticated, setApiIsAuthenticated] = useState<undefined | boolean>(undefined)

  useEffect(() => {
    getPingIsAuthenticated().then(isAuthenticated => setApiIsAuthenticated(isAuthenticated))
  }, [])

  const cookieIsAuthenticated = getCookiesIsLoggedIn(document.cookie)

  return (
    <AuthContext.Provider value={calculateAuthState({ apiIsAuthenticated, cookieIsAuthenticated })}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const calculateAuthState = ({
  apiIsAuthenticated,
  cookieIsAuthenticated,
}: {
  apiIsAuthenticated: boolean | undefined
  cookieIsAuthenticated: true | undefined
}) => ({
  isAuthenticated: apiIsAuthenticated ?? cookieIsAuthenticated,
})

const getCookiesIsLoggedIn = (cookie: string) => {
  const refreshTokenExpiresAt = getRefreshTokenExpiresAt(cookie)
  return typeof refreshTokenExpiresAt === 'string' || undefined
}

const getRefreshTokenExpiresAt = (cookie: string) =>
  cookie
    .split('; ')
    .find(row => row.startsWith('github_refresh_token_expires_at'))
    ?.split('=')[1] ?? undefined
