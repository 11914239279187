const BackArrowBlack = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0309 2.46936C17.1007 2.53903 17.1561 2.6218 17.1939 2.71291C17.2318 2.80403 17.2512 2.90171 17.2512 3.00036C17.2512 3.09902 17.2318 3.1967 17.1939 3.28781C17.1561 3.37893 17.1007 3.4617 17.0309 3.53136L8.56038 12.0004L17.0309 20.4694C17.1717 20.6102 17.2508 20.8012 17.2508 21.0004C17.2508 21.1995 17.1717 21.3905 17.0309 21.5314C16.89 21.6722 16.699 21.7513 16.4999 21.7513C16.3007 21.7513 16.1097 21.6722 15.9689 21.5314L6.96888 12.5314C6.89903 12.4617 6.84362 12.3789 6.80581 12.2878C6.768 12.1967 6.74854 12.099 6.74854 12.0004C6.74854 11.9017 6.768 11.804 6.80581 11.7129C6.84362 11.6218 6.89903 11.539 6.96888 11.4694L15.9689 2.46936C16.0385 2.39952 16.1213 2.34411 16.2124 2.3063C16.3035 2.26849 16.4012 2.24902 16.4999 2.24902C16.5985 2.24902 16.6962 2.26849 16.7873 2.3063C16.8784 2.34411 16.9612 2.39952 17.0309 2.46936Z"
        fill="#1C1533"
      />
    </svg>
  )
}
export default BackArrowBlack
