import { GitMergeIcon, GitPullRequestClosedIcon, GitPullRequestIcon } from '@primer/octicons-react'
import { match } from 'ts-pattern'
import * as styles from './pr-status-badge.css.ts'

type PRStatusBadgeProps = {
  variant: 'open' | 'merged' | 'closed' | 'auto-closed'
}

export function PRStatusBadge({ variant }: PRStatusBadgeProps) {
  const Variant = match(variant)
    .with('open', () => props => (
      <>
        <GitPullRequestIcon size={16} {...props} />
        Open
      </>
    ))
    .with('merged', () => props => (
      <>
        <GitMergeIcon size={16} {...props} />
        Merged
      </>
    ))
    .with('closed', () => props => (
      <>
        <GitPullRequestClosedIcon size={16} {...props} />
        Closed
      </>
    ))
    .with('auto-closed', () => props => (
      <>
        <GitPullRequestClosedIcon size={16} {...props} />
        Auto-closed
      </>
    ))
    .exhaustive()

  return (
    <span className={styles.badge[variant]}>
      <Variant />
    </span>
  )
}
