import { useContext, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AccountsNavigator } from '../components/accounts-navigator'
import { CoverageTable } from '../components/coverage-table'
import { RecentActivityTable } from '../components/recent-activity-table'
import { ThemeContext } from '../utils/use-theme'
import {
  useGetInstallations,
  useGetPixeebotPullRequests,
  useGetRepositories,
  useGetRepositoriesActivations,
  useGetRepositoriesAnalysisStatusLogs,
} from '../utils/user-platform-api-hooks'
import './account-page.css'
import { useOutletData } from './root'

export const AccountPage = () => {
  const { theme } = useContext(ThemeContext)
  const navigate = useNavigate()
  const user = useOutletData()
  const { accountLogin } = useParams()

  const { data: installations } = useGetInstallations()

  useEffect(() => {
    if (!installations) return
    const currentInstallation = installations.find(installation => installation.account.login === accountLogin)
    if (!currentInstallation) navigate('/')
  }, [installations])

  const { data: repositories } = useGetRepositories(
    installations
      ? installations
          .filter(installation => installation.account.login === accountLogin)
          .map(installation => installation.id)
      : []
  )

  let { data: allPixeebotPRs } = useGetPixeebotPullRequests(
    installations.map(installation => installation.account.login)
  )

  const { data: repositoriesActivation } = useGetRepositoriesActivations(
    installations.map(installation => installation.id)
  )

  const { data: analysesByRepository } = useGetRepositoriesAnalysisStatusLogs(
    installations.map(installation => installation.id)
  )

  if (!user || installations === undefined || repositories === undefined) return <></>

  const accountPRs = allPixeebotPRs.filter(pr => repositories.some(repository => repository.url === pr.repository_url))

  return (
    <main id={theme} className="container px-4 py-5">
      <nav aria-label="breadcrumb" style={{ marginBottom: 40 }}>
        <ol className="breadcrumb">
          <li className="breadcrumb-item inactive">
            <Link className="small" to="/installations">
              Installations
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Link className="small" to="">
              {accountLogin}
            </Link>
          </li>
        </ol>
      </nav>

      <div className="row">
        <div className="col-lg-3 d-flex flex-column align-items-start">
          <img
            src={repositories[0]?.owner?.avatar_url ? repositories[0]?.owner?.avatar_url : ''}
            width={200}
            height={200}
            alt=""
            style={{ borderRadius: '16px', marginBottom: 10 }}
          />
          <AccountsNavigator installations={installations} currentAccount={accountLogin} theme={theme} />
          <p className={`paragraph ${theme}-theme-default-text`}>{repositories[0]?.owner?.type}</p>
        </div>
        <div className="col-lg-9 d-flex flex-column" style={{ gap: '72px' }}>
          <div>
            <CoverageTable
              repositories={repositories}
              repositoriesActivation={repositoriesActivation}
              accountPRs={accountPRs}
              analysesByRepository={analysesByRepository}
            />
          </div>
          <div>
            <RecentActivityTable pixeebotPRs={accountPRs} />
          </div>
        </div>
      </div>
    </main>
  )
}
