const PullRequestClosedBlack = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>git_pull_request_close_fill</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-672.000000, -48.000000)" fillRule="nonzero">
          <g transform="translate(672.000000, 48.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              fillRule="nonzero"
            ></path>
            <path
              d="M6,2.99998 C7.65685,2.99998 9,4.34313 9,5.99998 C9,7.240889 8.24658398,8.30582855 7.1722376,8.76232155 L7,8.82928 L7,15.1707 C8.16519,15.5825 9,16.6938 9,18 C9,19.6568 7.65685,21 6,21 C4.34315,21 3,19.6568 3,18 C3,16.75911 3.75341603,15.6941173 4.8277624,15.2376532 L5,15.1707 L5,8.82928 C3.83481,8.41744 3,7.3062 3,5.99998 C3,4.34313 4.34315,2.99998 6,2.99998 Z M18,9.99998 C18.51285,9.99998 18.9355092,10.3860243 18.9932725,10.8833758 L19,11 L19,15.1707 C20.1652,15.5825 21,16.6938 21,18 C21,19.6568 19.6569,21 18,21 C16.3431,21 15,19.6568 15,18 C15,16.75911 15.753407,15.6941173 16.827761,15.2376532 L17,15.1707 L17,11 C17,10.4477 17.4477,9.99998 18,9.99998 Z M16.5857,3.17156 L17.9999,4.58577 L19.4141,3.17156 C19.8046,2.78103 20.4378,2.78103 20.8283,3.17156 C21.2189,3.56208 21.2189,4.19524 20.8283,4.58577 L19.4141,5.99998 L20.8283,7.4142 C21.2189,7.80472 21.2189,8.43789 20.8283,8.82841 C20.4378,9.21893 19.8046,9.21893 19.4141,8.82841 L17.9999,7.4142 L16.5857,8.82841 C16.1952,9.21893 15.562,9.21893 15.1715,8.82841 C14.781,8.43789 14.781,7.80472 15.1715,7.4142 L16.5857,5.99998 L15.1715,4.58577 C14.781,4.19524 14.781,3.56208 15.1715,3.17156 C15.562,2.78103 16.1952,2.78103 16.5857,3.17156 Z"
              fill="#1C1533"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default PullRequestClosedBlack
